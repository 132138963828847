/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  ClinicCounselorCounselingReport,
  ClinicCounselorCounselingReportFromJSON,
  ClinicCounselorCounselingReportToJSON,
} from "../models";

export interface GetCounselingReportRequest {
  from: string;
  to: string;
  clinicId?: number;
  departmentId?: number;
  limit?: number;
  offset?: number;
}

/**
 *
 */
export class CounselingReportApi extends runtime.BaseAPI {
  /**
   * Get counseling report
   */
  async getCounselingReportRaw(
    requestParameters: GetCounselingReportRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ClinicCounselorCounselingReport>>> {
    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling getCounselingReport."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling getCounselingReport."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.clinicId !== undefined) {
      queryParameters["clinic_id"] = requestParameters.clinicId;
    }

    if (requestParameters.departmentId !== undefined) {
      queryParameters["department_id"] = requestParameters.departmentId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counseling-reports`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicCounselorCounselingReportFromJSON));
  }

  /**
   * Get counseling report
   */
  async getCounselingReport(
    requestParameters: GetCounselingReportRequest,
    initOverrides?: RequestInit
  ): Promise<Array<ClinicCounselorCounselingReport>> {
    return promiseRetry(
      (retry) =>
        this.getCounselingReportRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
