import { noop } from "@mgdx/shared/src/utils/noop";
import { logger } from "@mgdx-libs/logger";

export type AppBridgeResponse<T> =
  | {
      status: "success";
      data: T;
    }
  | {
      status: "failure";
    };
type AppBridgeSubscriber = (message: string) => void;
export type SubscribeHandler<T> = (data: T) => void;

declare global {
  interface Window {
    yqbSendAppVersion: AppBridgeSubscriber;
    yqbSendAppNetUserId: AppBridgeSubscriber;
    yqbSendAppUserId: AppBridgeSubscriber;
    yqbSendNotificationPermissionStatus: AppBridgeSubscriber;
    yqbResumeAlarmSetting: AppBridgeSubscriber;
    yqbSendCameraPermissionStatus: AppBridgeSubscriber;
    yqbSendPhotoPermissionStatus: AppBridgeSubscriber;
    yqbSendLocationPermissionStatus: AppBridgeSubscriber;
    yqbSendScheduledNotificationConfig: AppBridgeSubscriber;
  }
}

const setSubscribeHandler =
  <T>(handler: SubscribeHandler<T>, errorHandler?: () => void) =>
  (message: string) => {
    let response: AppBridgeResponse<T>;

    try {
      response = JSON.parse(message);
    } catch (e) {
      logger.error("JSON parse error: " + e.message);
      return;
    }

    if (response.status === "success") {
      logger.debug("app bridge: Success");
      handler(response.data);
    } else if (response.status === "failure") {
      logger.error("app bridge: Failed to get data");
      errorHandler && errorHandler();
    } else {
      logger.error("app bridge: Invalid status");
    }
  };

export type GetAppVersionData = {
  version: string;
};
export const setYqbSendAppVersionHandler = (
  handler: SubscribeHandler<GetAppVersionData>,
  errorHandler?: () => void
): void => {
  window.yqbSendAppVersion = setSubscribeHandler(handler, errorHandler);
};
export const removeYqbSendAppVersionHandler = (): void => {
  window.yqbSendAppVersion = noop;
};

export type GetNetUserIdData = {
  netUserId: string;
};
export const setYqbSendAppNetUserIdHandler = (
  handler: SubscribeHandler<GetNetUserIdData>,
  errorHandler?: () => void
): void => {
  window.yqbSendAppNetUserId = setSubscribeHandler(handler, errorHandler);
};
export const removeYqbSendAppNetUserIdHandler = (): void => {
  window.yqbSendAppNetUserId = noop;
};

export type GetAppUserIdData = {
  appUserId: string;
};
export const setYqbSendAppUserIdHandler = (
  handler: SubscribeHandler<GetAppUserIdData>,
  errorHandler?: () => void
): void => {
  window.yqbSendAppUserId = setSubscribeHandler(handler, errorHandler);
};
export const removeYqbSendAppUserIdHandler = (): void => {
  window.yqbSendAppNetUserId = noop;
};

/**
 * 【定義】
 * granted: 許可している
 * denied: 許可していない
 * notDetermined: まだパーミッションリクエストを行ったことがない
 * restricted: [iOS のみ] ペアレンタルコントロールなどにより利用が制限されている
 * provisional: [iOS のみ] 一時的な許可 (※ いまのところこれを返す予定はなし)
 */
export enum NotificationPermissionStatus {
  Granted = "granted",
  Denied = "denied",
  NotDetermined = "notDetermined",
  Restricted = "restricted",
  Provisional = "provisional",
}
export type GetNotificationPermissionStatusData = {
  notificationPermissionStatus: `${NotificationPermissionStatus}`;
};
export const setYqbSendNotificationPermissionStatusHandler = (
  handler: SubscribeHandler<GetNotificationPermissionStatusData>,
  errorHandler?: () => void
): void => {
  window.yqbSendNotificationPermissionStatus = setSubscribeHandler(handler, errorHandler);
};
export const removeYqbSendNotificationPermissionStatusHandler = (): void => {
  window.yqbSendNotificationPermissionStatus = noop;
};
// アプリのライフサイクルで呼ばれるためhandler未設定でも呼ばれる可能性があるため初期値を設定しておく
if (typeof window !== "undefined") {
  window.yqbResumeAlarmSetting = noop;
}
// native appのlifecycleのonResumeのタイミングで呼ばれる
// -> 端末の通知設定を完了してアプリに戻ってきた時
export const setYqbResumeAlarmSettingHandler = (
  handler: SubscribeHandler<GetNotificationPermissionStatusData>,
  errorHandler?: () => void
): void => {
  window.yqbResumeAlarmSetting = setSubscribeHandler(handler, errorHandler);
};
export const removeYqbResumeAlarmSettingHandler = (): void => {
  window.yqbResumeAlarmSetting = noop;
};

/**
 * granted: 許可している
 * denied: 許可していない
 * notDetermined: まだパーミッションリクエストを行ったことがない
 * restricted: [iOS のみ] ペアレンタルコントロールなどにより利用が制限されている"
 */
enum CameraPermissionStatus {
  Granted = "granted",
  Denied = "denied",
  NotDetermined = "notDetermined",
  Restricted = "restricted",
}
export type GetCameraPermissionStatusData = {
  cameraPermissionStatus: `${CameraPermissionStatus}`;
};
export const setYqbSendCameraPermissionStatusHandler = (
  handler: SubscribeHandler<GetCameraPermissionStatusData>,
  errorHandler?: () => void
): void => {
  window.yqbSendCameraPermissionStatus = setSubscribeHandler(handler, errorHandler);
};
export const removeYqbSendCameraPermissionStatusHandler = (): void => {
  window.yqbSendCameraPermissionStatus = noop;
};

/**
 * granted: 許可している
 * denied: 許可していない
 * notDetermined: まだパーミッションリクエストを行ったことがない
 * limited: [iOS のみ] 制限つきでアクセスを許可している
 * restricted: [iOS のみ] ペアレンタルコントロールなどにより利用が制限されている"
 */
enum PhotoPermissionStatus {
  Granted = "granted",
  Denied = "denied",
  NotDetermined = "notDetermined",
  Limited = "limited",
  Restricted = "restricted",
}
export type GetPhotoPermissionStatusData = {
  photoPermissionStatus: `${PhotoPermissionStatus}`;
};
export const setYqbSendPhotoPermissionStatusHandler = (
  handler: SubscribeHandler<GetPhotoPermissionStatusData>,
  errorHandler?: () => void
): void => {
  window.yqbSendPhotoPermissionStatus = setSubscribeHandler(handler, errorHandler);
};
export const removeYqbSendPhotoPermissionStatusHandler = (): void => {
  window.yqbSendPhotoPermissionStatus = noop;
};

/**
 * granted: 許可している
 * denied: 許可していない
 * notDetermined: まだパーミッションリクエストを行ったことがない
 * restricted: [iOS のみ] ペアレンタルコントロールなどにより利用が制限されている"
 */
export enum LocationPermissionStatus {
  Granted = "granted",
  Denied = "denied",
  NotDetermined = "notDetermined",
  Restricted = "restricted",
}
export type GetLocationPermissionStatusData = {
  locationPermissionStatus: `${LocationPermissionStatus}`;
};
export const setYqbSendLocationPermissionStatusHandler = (
  handler: SubscribeHandler<GetLocationPermissionStatusData>,
  errorHandler?: () => void
): void => {
  window.yqbSendLocationPermissionStatus = setSubscribeHandler(handler, errorHandler);
};
export const removeYqbSendLocationPermissionStatusHandler = (): void => {
  window.yqbSendLocationPermissionStatus = noop;
};

export type GetScheduledNotificationConfigData = {
  limit: number;
  oldAlarmExists: boolean;
};
export const setYqbSendScheduledNotificationConfigHandler = (
  handler: SubscribeHandler<GetScheduledNotificationConfigData>,
  errorHandler?: () => void
): void => {
  window.yqbSendScheduledNotificationConfig = setSubscribeHandler(handler, errorHandler);
};
export const removeYqbSendScheduledNotificationNotificationHandler = (): void => {
  window.yqbSendScheduledNotificationConfig = noop;
};
