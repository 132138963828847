import OpenInNewIcon from "@mgdx/assets/icons/OpenInNewIcon";
import { FilledButton } from "@mgdx/ui/components/FilledButton";
import { InputCheckbox } from "@mgdx/ui/components/InputCheckbox";
import { MessagePlain } from "@mgdx/ui/components/MessagePlain";
import { PlainLinkButton } from "@mgdx/ui/components/PlainLink";
import Title from "@mgdx/ui/components/Title";
import React, { FC, useState } from "react";

import { PlainLinkExternal } from "../../ui/PlainLinkExternal";

export type AgreementNewTermsConfirmViewProps = {
  isLoading?: boolean;
  onClickAgreement: VoidFunction;
  onClickShowReason: VoidFunction;
};

export const AgreementNewTermsConfirmView: FC<AgreementNewTermsConfirmViewProps> = ({
  isLoading,
  onClickAgreement,
  onClickShowReason,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="p-4">
      <Title variant="basic_center">薬急便利用規約が変更されました</Title>

      <div className="mt-4 flex flex-col gap-4">
        <MessagePlain textAlign="center" variant="info">
          引き続きご利用になるには薬急便利用規約への再同意が必要です。
        </MessagePlain>

        <PlainLinkExternal
          href={process.env.MGDX_YQB_ROOT_DOMAIN_URL + "/rules/terms/"}
          title="薬急便利用規約"
          widthType="full-width"
        >
          薬急便利用規約
          <OpenInNewIcon size="s" variant="text_link" />
        </PlainLinkExternal>
      </div>

      <div className="flex flex-col items-center justify-center gap-2">
        <InputCheckbox
          checked={isChecked}
          className="!flex justify-center"
          data-testid="check-confirm"
          onChange={(e) => setIsChecked(e.target.checked)}
        >
          利用規約を確認しました
        </InputCheckbox>

        <FilledButton
          data-testid="agreement-button"
          disabled={!isChecked}
          loading={isLoading}
          onClick={onClickAgreement}
          type="normal"
          variant="primary"
          widthType="full-width"
        >
          同意して利用する
        </FilledButton>

        <PlainLinkButton data-testid="show-reason-button" onClick={onClickShowReason} widthType="full-width">
          詳しい説明を見る
        </PlainLinkButton>
      </div>
    </div>
  );
};
