import appName from "@mgdx/shared/src/appName";
import appVersion from "@mgdx/shared/src/appVersion";
import * as Sentry from "@sentry/react";

const ignoreExceptionValues = [
  "Load failed",
  "ネットワーク接続が切れました。",
  "キャンセルしました",
  "network error on stackdriver report",
  "[auth/network-request-failed] Firebase: Error (auth/network-request-failed).",
  "Firebase: Error (auth/network-request-failed).",
  "操作を完了できませんでした。ソフトウェアにより接続が中止されました",
  "Unexpected token '<'",
  "N",
  "Non-Error promise rejection captured with value: Timeout",
  "UnhandledRejection: Non-Error promise rejection captured with value: Timeout",
  "null is not an object (evaluating 'window.localStorage.setItem')",
  "null is not an object (evaluating 'Object.keys(localStorage)')",
  "null is not an object (evaluating 'window.localStorage.getItem')",
  "null is not an object (evaluating 'localStorage.debug=\"\"')",
  "Firebase: Error thrown when reading from storage. Original error: Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.. (app/storage-get).",
  "TimeoutError: Transaction timed out due to inactivity.",
  "操作を完了できませんでした。（kCFErrorDomainCFNetworkエラー303）",
  "Unhandled promise rejection.  TypeError: Load failed",
  "UnknownError: Connection to Indexed Database server lost. Refresh the page to try again",
  "Unhandled promise rejection.  UnknownError: Connection to Indexed Database server lost. Refresh the page to try again",
  "Failed to fetch",
  "Failed to execute 'json' on 'Response': body stream already read",
];

const ignoreExceptionPreMatchValues = ["Failed to fetch"];

export function initSentry({ dsn }: Pick<Sentry.BrowserOptions, "dsn">) {
  if (!dsn) return;
  const isProduction = process.env.BUILD_ENV === "prd";

  Sentry.init({
    dsn,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: isProduction ? 0.001 : 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    environment: process.env.BUILD_ENV ?? "dev",
    release: `${appName}.${process.env.BUILD_ENV}.${appVersion}`,
    debug: !isProduction,
    beforeSend(event) {
      if (
        event.exception?.values &&
        event.exception.values.some((exception) => {
          const value = exception.value;
          if (!value) return false;
          if (ignoreExceptionValues.includes(value)) return true;
          return ignoreExceptionPreMatchValues.some((pre) => value.startsWith(pre));
        })
      ) {
        return null;
      }
      return event;
    },
  });
}
