import clsx from "clsx";
import React, { Fragment, ReactNode, useId } from "react";

import { BaseInputMin, BaseInputMinProps } from "../core/BaseInputMin";
import { CommonInputWithMessageProps, InputFieldMessage } from "../InputFieldMessage";
import * as styles from "./ToggleInputGroup.module.css";

export type ToggleInputGroupBaseMinProps = Pick<BaseInputMinProps, "disabled" | "completed" | "error" | "children"> &
  CommonInputWithMessageProps & {
    id?: string;
    items?: Array<ReactNode>;
    className?: string;
  };

export const ToggleInputGroupBaseMin: React.FC<ToggleInputGroupBaseMinProps> = ({
  /* BaseInputMinProps */
  error,
  disabled,
  completed,
  /* CommonInputWithMessageProps */
  message,
  noMessageArea,
  /* my props */
  id,
  items,
  children,
  className,
}) => {
  const controlId = useId();

  return (
    <div className={clsx(styles.container, className)}>
      <BaseInputMin
        disabled={disabled}
        completed={completed}
        error={error}
        aria-describedby={!noMessageArea ? `describe-${id || controlId}` : undefined}
        className={clsx(styles.base, styles.min, disabled && styles.disabled)}
        isGroup={true}
      >
        {children ||
          items?.map((item, index) => (
            <Fragment key={`item_${index}`}>
              {index !== 0 && <div className={styles.divider} />}
              {item}
            </Fragment>
          ))}
      </BaseInputMin>
      {!noMessageArea && (
        <InputFieldMessage id={`describe-${id || controlId}`} error={error} disabled={disabled}>
          {message}
        </InputFieldMessage>
      )}
    </div>
  );
};
