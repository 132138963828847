/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatientCustomBadRequest4,
  PatientCustomBadRequest4FromJSON,
  PatientCustomBadRequest4ToJSON,
  PatientMedicineNotebookShare,
  PatientMedicineNotebookShareFromJSON,
  PatientMedicineNotebookShareToJSON,
  PatientMedicineNotebookShareIssue,
  PatientMedicineNotebookShareIssueFromJSON,
  PatientMedicineNotebookShareIssueToJSON,
  PatientMedicineNotebookSharePair,
  PatientMedicineNotebookSharePairFromJSON,
  PatientMedicineNotebookSharePairToJSON,
  PatientMedicineNotebookShareRequest,
  PatientMedicineNotebookShareRequestFromJSON,
  PatientMedicineNotebookShareRequestToJSON,
  PostMedicineNotebookShareIssueRequestBody,
  PostMedicineNotebookShareIssueRequestBodyFromJSON,
  PostMedicineNotebookShareIssueRequestBodyToJSON,
} from "../models";

export interface AcceptMedicineNotebookShareRequestRequest {
  medicineNotebookShareRequestId: string;
}

export interface DeleteFamilyAccountMedicineNotebookShareRequest {
  medicineNotebookShareId: string;
  familyAccountId: number;
}

export interface DeleteMedicineNotebookShareRequestRequest {
  medicineNotebookShareRequestId: string;
  type: DeleteMedicineNotebookShareRequestTypeEnum;
}

export interface DeletePatientMedicineNotebookShareRequest {
  medicineNotebookShareId: string;
  patientId: number;
}

export interface GetMedicineNotebookShareIssueRequest {
  shareCode: string;
}

export interface GetMedicineNotebookShareRequestsRequest {
  whose: GetMedicineNotebookShareRequestsWhoseEnum;
}

export interface GetPatientMedicineNotebookShareIssueRequest {
  medicineNotebookShareIssueId: string;
}

export interface PostMedicineNotebookShareIssueRequest {
  postMedicineNotebookShareIssueRequestBody: PostMedicineNotebookShareIssueRequestBody;
}

export interface PostMedicineNotebookShareRequestRequest {
  medicineNotebookShareIssueId: string;
}

/**
 *
 */
export class MedicineNotebookShareApi extends runtime.BaseAPI {
  /**
   * Accept medicine notebook share request
   */
  async acceptMedicineNotebookShareRequestRaw(
    requestParameters: AcceptMedicineNotebookShareRequestRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineNotebookShare>> {
    if (
      requestParameters.medicineNotebookShareRequestId === null ||
      requestParameters.medicineNotebookShareRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        "medicineNotebookShareRequestId",
        "Required parameter requestParameters.medicineNotebookShareRequestId was null or undefined when calling acceptMedicineNotebookShareRequest."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebook-share-requests/{medicine_notebook_share_request_id}/accept`.replace(
          `{${"medicine_notebook_share_request_id"}}`,
          encodeURIComponent(String(requestParameters.medicineNotebookShareRequestId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineNotebookShareFromJSON(jsonValue));
  }

  /**
   * Accept medicine notebook share request
   */
  async acceptMedicineNotebookShareRequest(
    requestParameters: AcceptMedicineNotebookShareRequestRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineNotebookShare> {
    return promiseRetry(
      (retry) =>
        this.acceptMedicineNotebookShareRequestRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Delete specific family account\'s medicine notebook share
   */
  async deleteFamilyAccountMedicineNotebookShareRaw(
    requestParameters: DeleteFamilyAccountMedicineNotebookShareRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.medicineNotebookShareId === null || requestParameters.medicineNotebookShareId === undefined) {
      throw new runtime.RequiredError(
        "medicineNotebookShareId",
        "Required parameter requestParameters.medicineNotebookShareId was null or undefined when calling deleteFamilyAccountMedicineNotebookShare."
      );
    }

    if (requestParameters.familyAccountId === null || requestParameters.familyAccountId === undefined) {
      throw new runtime.RequiredError(
        "familyAccountId",
        "Required parameter requestParameters.familyAccountId was null or undefined when calling deleteFamilyAccountMedicineNotebookShare."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebook-shares/{medicine_notebook_share_id}/family_accounts/{family_account_id}`
          .replace(
            `{${"medicine_notebook_share_id"}}`,
            encodeURIComponent(String(requestParameters.medicineNotebookShareId))
          )
          .replace(`{${"family_account_id"}}`, encodeURIComponent(String(requestParameters.familyAccountId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specific family account\'s medicine notebook share
   */
  async deleteFamilyAccountMedicineNotebookShare(
    requestParameters: DeleteFamilyAccountMedicineNotebookShareRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteFamilyAccountMedicineNotebookShareRaw(requestParameters, initOverrides);
  }

  /**
   * Reject or cancel medicine notebook share request
   */
  async deleteMedicineNotebookShareRequestRaw(
    requestParameters: DeleteMedicineNotebookShareRequestRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.medicineNotebookShareRequestId === null ||
      requestParameters.medicineNotebookShareRequestId === undefined
    ) {
      throw new runtime.RequiredError(
        "medicineNotebookShareRequestId",
        "Required parameter requestParameters.medicineNotebookShareRequestId was null or undefined when calling deleteMedicineNotebookShareRequest."
      );
    }

    if (requestParameters.type === null || requestParameters.type === undefined) {
      throw new runtime.RequiredError(
        "type",
        "Required parameter requestParameters.type was null or undefined when calling deleteMedicineNotebookShareRequest."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.type !== undefined) {
      queryParameters["type"] = requestParameters.type;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebook-share-requests/{medicine_notebook_share_request_id}`.replace(
          `{${"medicine_notebook_share_request_id"}}`,
          encodeURIComponent(String(requestParameters.medicineNotebookShareRequestId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Reject or cancel medicine notebook share request
   */
  async deleteMedicineNotebookShareRequest(
    requestParameters: DeleteMedicineNotebookShareRequestRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteMedicineNotebookShareRequestRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specific patient\'s medicine notebook share
   */
  async deletePatientMedicineNotebookShareRaw(
    requestParameters: DeletePatientMedicineNotebookShareRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.medicineNotebookShareId === null || requestParameters.medicineNotebookShareId === undefined) {
      throw new runtime.RequiredError(
        "medicineNotebookShareId",
        "Required parameter requestParameters.medicineNotebookShareId was null or undefined when calling deletePatientMedicineNotebookShare."
      );
    }

    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling deletePatientMedicineNotebookShare."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebook-shares/{medicine_notebook_share_id}/patients/{patient_id}`
          .replace(
            `{${"medicine_notebook_share_id"}}`,
            encodeURIComponent(String(requestParameters.medicineNotebookShareId))
          )
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specific patient\'s medicine notebook share
   */
  async deletePatientMedicineNotebookShare(
    requestParameters: DeletePatientMedicineNotebookShareRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deletePatientMedicineNotebookShareRaw(requestParameters, initOverrides);
  }

  /**
   * Get medicine notebook share issue by share code
   */
  async getMedicineNotebookShareIssueRaw(
    requestParameters: GetMedicineNotebookShareIssueRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineNotebookShareIssue>> {
    if (requestParameters.shareCode === null || requestParameters.shareCode === undefined) {
      throw new runtime.RequiredError(
        "shareCode",
        "Required parameter requestParameters.shareCode was null or undefined when calling getMedicineNotebookShareIssue."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.shareCode !== undefined) {
      queryParameters["share_code"] = requestParameters.shareCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/medicine-notebook-share-issue`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineNotebookShareIssueFromJSON(jsonValue));
  }

  /**
   * Get medicine notebook share issue by share code
   */
  async getMedicineNotebookShareIssue(
    requestParameters: GetMedicineNotebookShareIssueRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineNotebookShareIssue> {
    return promiseRetry(
      (retry) =>
        this.getMedicineNotebookShareIssueRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get patient\'s medicine notebook share pairs
   */
  async getMedicineNotebookSharePairsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PatientMedicineNotebookSharePair>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebook-share-pairs`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PatientMedicineNotebookSharePairFromJSON)
    );
  }

  /**
   * Get patient\'s medicine notebook share pairs
   */
  async getMedicineNotebookSharePairs(initOverrides?: RequestInit): Promise<Array<PatientMedicineNotebookSharePair>> {
    return promiseRetry(
      (retry) =>
        this.getMedicineNotebookSharePairsRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get patient\'s medicine notebook share requests
   */
  async getMedicineNotebookShareRequestsRaw(
    requestParameters: GetMedicineNotebookShareRequestsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PatientMedicineNotebookShareRequest>>> {
    if (requestParameters.whose === null || requestParameters.whose === undefined) {
      throw new runtime.RequiredError(
        "whose",
        "Required parameter requestParameters.whose was null or undefined when calling getMedicineNotebookShareRequests."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.whose !== undefined) {
      queryParameters["whose"] = requestParameters.whose;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebook-share-requests`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PatientMedicineNotebookShareRequestFromJSON)
    );
  }

  /**
   * Get patient\'s medicine notebook share requests
   */
  async getMedicineNotebookShareRequests(
    requestParameters: GetMedicineNotebookShareRequestsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PatientMedicineNotebookShareRequest>> {
    return promiseRetry(
      (retry) =>
        this.getMedicineNotebookShareRequestsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get patient\'s medicine notebook share issue by id
   */
  async getPatientMedicineNotebookShareIssueRaw(
    requestParameters: GetPatientMedicineNotebookShareIssueRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineNotebookShareIssue>> {
    if (
      requestParameters.medicineNotebookShareIssueId === null ||
      requestParameters.medicineNotebookShareIssueId === undefined
    ) {
      throw new runtime.RequiredError(
        "medicineNotebookShareIssueId",
        "Required parameter requestParameters.medicineNotebookShareIssueId was null or undefined when calling getPatientMedicineNotebookShareIssue."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebook-share-issues/{medicine_notebook_share_issue_id}`.replace(
          `{${"medicine_notebook_share_issue_id"}}`,
          encodeURIComponent(String(requestParameters.medicineNotebookShareIssueId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineNotebookShareIssueFromJSON(jsonValue));
  }

  /**
   * Get patient\'s medicine notebook share issue by id
   */
  async getPatientMedicineNotebookShareIssue(
    requestParameters: GetPatientMedicineNotebookShareIssueRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineNotebookShareIssue> {
    return promiseRetry(
      (retry) =>
        this.getPatientMedicineNotebookShareIssueRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Post medicine notebook share issue
   */
  async postMedicineNotebookShareIssueRaw(
    requestParameters: PostMedicineNotebookShareIssueRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineNotebookShareIssue>> {
    if (
      requestParameters.postMedicineNotebookShareIssueRequestBody === null ||
      requestParameters.postMedicineNotebookShareIssueRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postMedicineNotebookShareIssueRequestBody",
        "Required parameter requestParameters.postMedicineNotebookShareIssueRequestBody was null or undefined when calling postMedicineNotebookShareIssue."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebook-share-issues`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostMedicineNotebookShareIssueRequestBodyToJSON(
          requestParameters.postMedicineNotebookShareIssueRequestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineNotebookShareIssueFromJSON(jsonValue));
  }

  /**
   * Post medicine notebook share issue
   */
  async postMedicineNotebookShareIssue(
    requestParameters: PostMedicineNotebookShareIssueRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineNotebookShareIssue> {
    return promiseRetry(
      (retry) =>
        this.postMedicineNotebookShareIssueRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Post medicine notebook share request to issue
   */
  async postMedicineNotebookShareRequestRaw(
    requestParameters: PostMedicineNotebookShareRequestRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineNotebookShareRequest>> {
    if (
      requestParameters.medicineNotebookShareIssueId === null ||
      requestParameters.medicineNotebookShareIssueId === undefined
    ) {
      throw new runtime.RequiredError(
        "medicineNotebookShareIssueId",
        "Required parameter requestParameters.medicineNotebookShareIssueId was null or undefined when calling postMedicineNotebookShareRequest."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/medicine-notebook-share-issues/{medicine_notebook_share_issue_id}/request`.replace(
          `{${"medicine_notebook_share_issue_id"}}`,
          encodeURIComponent(String(requestParameters.medicineNotebookShareIssueId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineNotebookShareRequestFromJSON(jsonValue));
  }

  /**
   * Post medicine notebook share request to issue
   */
  async postMedicineNotebookShareRequest(
    requestParameters: PostMedicineNotebookShareRequestRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineNotebookShareRequest> {
    return promiseRetry(
      (retry) =>
        this.postMedicineNotebookShareRequestRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum DeleteMedicineNotebookShareRequestTypeEnum {
  Cancel = "cancel",
  Reject = "reject",
}
/**
 * @export
 * @enum {string}
 */
export enum GetMedicineNotebookShareRequestsWhoseEnum {
  Mine = "mine",
  Theirs = "theirs",
}
