/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  ClinicCounselorCounseling,
  ClinicCounselorCounselingFromJSON,
  ClinicCounselorCounselingToJSON,
  ClinicCounselorCounselings,
  ClinicCounselorCounselingsFromJSON,
  ClinicCounselorCounselingsToJSON,
  ClinicCounselorCustomBadRequest1,
  ClinicCounselorCustomBadRequest1FromJSON,
  ClinicCounselorCustomBadRequest1ToJSON,
  ClinicCounselorCustomBadRequest2,
  ClinicCounselorCustomBadRequest2FromJSON,
  ClinicCounselorCustomBadRequest2ToJSON,
  PatchCounselingRequestBody,
  PatchCounselingRequestBodyFromJSON,
  PatchCounselingRequestBodyToJSON,
  PostCounselingRequestBody,
  PostCounselingRequestBodyFromJSON,
  PostCounselingRequestBodyToJSON,
} from "../models";

export interface GetCounselingRequest {
  counselingId: number;
}

export interface GetCounselingsRequest {
  from?: string;
  to?: string;
  departmentId?: number;
  doctorId?: number;
  patientId?: number;
  patientName?: string;
  familyAccountId?: number;
  status?: GetCounselingsStatusEnum;
  billingStatus?: GetCounselingsBillingStatusEnum;
  pharmacyCounselingStatus?: GetCounselingsPharmacyCounselingStatusEnum;
  limit?: number;
  offset?: number;
  orderBy?: GetCounselingsOrderByEnum;
}

export interface PatchCounselingRequest {
  counselingId: number;
  patchCounselingRequestBody: PatchCounselingRequestBody;
}

export interface PostCounselingRequest {
  postCounselingRequestBody: PostCounselingRequestBody;
}

/**
 *
 */
export class CounselingApi extends runtime.BaseAPI {
  /**
   * Get counseling
   */
  async getCounselingRaw(
    requestParameters: GetCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicCounselorCounseling>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling getCounseling."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicCounselorCounselingFromJSON(jsonValue));
  }

  /**
   * Get counseling
   */
  async getCounseling(
    requestParameters: GetCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<ClinicCounselorCounseling> {
    return promiseRetry(
      (retry) =>
        this.getCounselingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get counselings using JWT. If you are a patient, you can get only your own, if you are a doctor, you can get all of your clinic\'s.
   */
  async getCounselingsRaw(
    requestParameters: GetCounselingsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicCounselorCounselings>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.departmentId !== undefined) {
      queryParameters["department_id"] = requestParameters.departmentId;
    }

    if (requestParameters.doctorId !== undefined) {
      queryParameters["doctor_id"] = requestParameters.doctorId;
    }

    if (requestParameters.patientId !== undefined) {
      queryParameters["patient_id"] = requestParameters.patientId;
    }

    if (requestParameters.patientName !== undefined) {
      queryParameters["patient_name"] = requestParameters.patientName;
    }

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.billingStatus !== undefined) {
      queryParameters["billing_status"] = requestParameters.billingStatus;
    }

    if (requestParameters.pharmacyCounselingStatus !== undefined) {
      queryParameters["pharmacy_counseling_status"] = requestParameters.pharmacyCounselingStatus;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["order_by"] = requestParameters.orderBy;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicCounselorCounselingsFromJSON(jsonValue));
  }

  /**
   * Get counselings using JWT. If you are a patient, you can get only your own, if you are a doctor, you can get all of your clinic\'s.
   */
  async getCounselings(
    requestParameters: GetCounselingsRequest,
    initOverrides?: RequestInit
  ): Promise<ClinicCounselorCounselings> {
    return promiseRetry(
      (retry) =>
        this.getCounselingsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update counseling
   */
  async patchCounselingRaw(
    requestParameters: PatchCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicCounselorCounseling>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling patchCounseling."
      );
    }

    if (
      requestParameters.patchCounselingRequestBody === null ||
      requestParameters.patchCounselingRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchCounselingRequestBody",
        "Required parameter requestParameters.patchCounselingRequestBody was null or undefined when calling patchCounseling."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchCounselingRequestBodyToJSON(requestParameters.patchCounselingRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicCounselorCounselingFromJSON(jsonValue));
  }

  /**
   * Update counseling
   */
  async patchCounseling(
    requestParameters: PatchCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<ClinicCounselorCounseling> {
    return promiseRetry(
      (retry) =>
        this.patchCounselingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Request Counseling Reservation
   */
  async postCounselingRaw(
    requestParameters: PostCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicCounselorCounseling>> {
    if (
      requestParameters.postCounselingRequestBody === null ||
      requestParameters.postCounselingRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postCounselingRequestBody",
        "Required parameter requestParameters.postCounselingRequestBody was null or undefined when calling postCounseling."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostCounselingRequestBodyToJSON(requestParameters.postCounselingRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicCounselorCounselingFromJSON(jsonValue));
  }

  /**
   * Request Counseling Reservation
   */
  async postCounseling(
    requestParameters: PostCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<ClinicCounselorCounseling> {
    return promiseRetry(
      (retry) =>
        this.postCounselingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsStatusEnum {
  Requested = "requested",
  Canceled = "canceled",
  Accepted = "accepted",
  Waiting = "waiting",
  CounselingStarted = "counseling_started",
  CounselingCompleted = "counseling_completed",
  BillingProcessing = "billing_processing",
  BillingCompleted = "billing_completed",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsBillingStatusEnum {
  Initialized = "initialized",
  Started = "started",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsPharmacyCounselingStatusEnum {
  None = "none",
  Required = "required",
  Requested = "requested",
  NotRequired = "not_required",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsOrderByEnum {
  Date = "date",
  DateDesc = "date_desc",
}
