import { logger } from "@mgdx-libs/logger";

type Urls = Array<string | RegExp>;

const INDEXED_PHARMACY_URLS: Urls = [new RegExp(/^\/pharmacies\/\d*\/(tokushoho\/)?$/)];

const INDEXED_OTHER_URLS: Urls = [
  "/contact/",
  "/inquiry/",
  new RegExp(/^\/clinics\/\d*\/(tokushoho\/)?$/),
  new RegExp(/^\/pharmacy-chains\/\d*\/(tokushoho\/)?$/),
  new RegExp(/^\/rules\/(.*\/)?/),
];

export type IndexedPageType = "ROOT_PAGE" | "INDEXED_PAGE" | "INDEXED_PHARMACY_PAGE" | "NONE";

export const getIndexedPageType = (pathname: string): IndexedPageType => {
  if (pathname === "/") {
    logger.debug("indexed-content-plugin: %s is ROOT_PAGE", pathname);
    return "ROOT_PAGE";
  }

  const isIndexedPharmacyUrl = !!INDEXED_PHARMACY_URLS.find((url) =>
    typeof url === "string" ? pathname === url : url.test(pathname)
  );

  if (isIndexedPharmacyUrl) {
    logger.debug("indexed-content-plugin: %s is INDEXED_PHARMACY_PAGE", pathname);
    return "INDEXED_PHARMACY_PAGE";
  }

  const isIndexedOtherUrl = !!INDEXED_OTHER_URLS.find((url) =>
    typeof url === "string" ? pathname === url : url.test(pathname)
  );

  if (isIndexedOtherUrl) {
    logger.debug("indexed-content-plugin: %s is INDEXED_PAGE", pathname);
    return "INDEXED_PAGE";
  }

  logger.debug("indexed-content-plugin: %s is NONE", pathname);
  return "NONE";
};
