const IS_FORCE_NO_INDEX = process.env.ENABLE_NO_INDEX === "true";

export const NO_INDEX_META = [
  { name: "robots", content: "noindex" },
  { name: "googlebot", content: "noindex" },
];

export const INDEXED_META = IS_FORCE_NO_INDEX
  ? NO_INDEX_META
  : [
      { name: "robots", content: "index, follow" },
      { name: "googlebot", content: "index, follow" },
    ];
