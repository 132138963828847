/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PharmacyCustomForbiddenError,
  PharmacyCustomForbiddenErrorFromJSON,
  PharmacyCustomForbiddenErrorToJSON,
  PharmacyPharmacist,
  PharmacyPharmacistFromJSON,
  PharmacyPharmacistToJSON,
  PharmacyPharmacistRegistrable,
  PharmacyPharmacistRegistrableFromJSON,
  PharmacyPharmacistRegistrableToJSON,
  PharmacyPharmacistUpdatable,
  PharmacyPharmacistUpdatableFromJSON,
  PharmacyPharmacistUpdatableToJSON,
  PharmacyPharmacists,
  PharmacyPharmacistsFromJSON,
  PharmacyPharmacistsToJSON,
  PostPharmacistResetPasswordRequestBody,
  PostPharmacistResetPasswordRequestBodyFromJSON,
  PostPharmacistResetPasswordRequestBodyToJSON,
  PutPharmacistPasswordRequestBody,
  PutPharmacistPasswordRequestBodyFromJSON,
  PutPharmacistPasswordRequestBodyToJSON,
} from "../models";

export interface DeletePharmacistRequest {
  pharmacistId: number;
}

export interface GetPharmacistsRequest {
  id?: Array<number>;
  chainId?: number;
  pharmacyId?: number;
  limit?: number;
  offset?: number;
}

export interface PatchPharmacistPasswordRequest {
  putPharmacistPasswordRequestBody: PutPharmacistPasswordRequestBody;
}

export interface PostPharmacistResetPasswordsRequest {
  postPharmacistResetPasswordRequestBody: PostPharmacistResetPasswordRequestBody;
}

export interface PostPharmacistsRequest {
  pharmacyPharmacistRegistrable: Array<PharmacyPharmacistRegistrable>;
}

export interface UpdatePharmacistRequest {
  pharmacistId: number;
  pharmacyPharmacistUpdatable: PharmacyPharmacistUpdatable;
}

/**
 *
 */
export class PharmacistApi extends runtime.BaseAPI {
  /**
   * Delete pharmacist
   */
  async deletePharmacistRaw(
    requestParameters: DeletePharmacistRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.pharmacistId === null || requestParameters.pharmacistId === undefined) {
      throw new runtime.RequiredError(
        "pharmacistId",
        "Required parameter requestParameters.pharmacistId was null or undefined when calling deletePharmacist."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacists/{pharmacist_id}`.replace(
          `{${"pharmacist_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacistId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete pharmacist
   */
  async deletePharmacist(requestParameters: DeletePharmacistRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deletePharmacistRaw(requestParameters, initOverrides);
  }

  /**
   * Logout pharmacist by auth_id from JWT
   */
  async deletePharmacistSessionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacist/session`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Logout pharmacist by auth_id from JWT
   */
  async deletePharmacistSession(initOverrides?: RequestInit): Promise<void> {
    await this.deletePharmacistSessionRaw(initOverrides);
  }

  /**
   * Get pharmacist by auth_id from JWT
   */
  async getPharmacistRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PharmacyPharmacist>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacist`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacistFromJSON(jsonValue));
  }

  /**
   * Get pharmacist by auth_id from JWT
   */
  async getPharmacist(initOverrides?: RequestInit): Promise<PharmacyPharmacist> {
    return promiseRetry(
      (retry) =>
        this.getPharmacistRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Search pharmacist by condition
   */
  async getPharmacistsRaw(
    requestParameters: GetPharmacistsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacists>> {
    const queryParameters: any = {};

    if (requestParameters.id) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    if (requestParameters.pharmacyId !== undefined) {
      queryParameters["pharmacy_id"] = requestParameters.pharmacyId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacists`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacistsFromJSON(jsonValue));
  }

  /**
   * Search pharmacist by condition
   */
  async getPharmacists(
    requestParameters: GetPharmacistsRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacists> {
    return promiseRetry(
      (retry) =>
        this.getPharmacistsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update own password with auth_id in JWT
   */
  async patchPharmacistPasswordRaw(
    requestParameters: PatchPharmacistPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacist>> {
    if (
      requestParameters.putPharmacistPasswordRequestBody === null ||
      requestParameters.putPharmacistPasswordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putPharmacistPasswordRequestBody",
        "Required parameter requestParameters.putPharmacistPasswordRequestBody was null or undefined when calling patchPharmacistPassword."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacist/password`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutPharmacistPasswordRequestBodyToJSON(requestParameters.putPharmacistPasswordRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacistFromJSON(jsonValue));
  }

  /**
   * Update own password with auth_id in JWT
   */
  async patchPharmacistPassword(
    requestParameters: PatchPharmacistPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacist> {
    return promiseRetry(
      (retry) =>
        this.patchPharmacistPasswordRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Accept password reset complete by auth_id from JWT
   */
  async postPharmacistPasswordResetCompletionEmailsRaw(
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacist/reset-password-completions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accept password reset complete by auth_id from JWT
   */
  async postPharmacistPasswordResetCompletionEmails(initOverrides?: RequestInit): Promise<void> {
    await this.postPharmacistPasswordResetCompletionEmailsRaw(initOverrides);
  }

  /**
   * Send a password reset email
   */
  async postPharmacistResetPasswordsRaw(
    requestParameters: PostPharmacistResetPasswordsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.postPharmacistResetPasswordRequestBody === null ||
      requestParameters.postPharmacistResetPasswordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postPharmacistResetPasswordRequestBody",
        "Required parameter requestParameters.postPharmacistResetPasswordRequestBody was null or undefined when calling postPharmacistResetPasswords."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/pharmacist-reset-passwords`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostPharmacistResetPasswordRequestBodyToJSON(requestParameters.postPharmacistResetPasswordRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send a password reset email
   */
  async postPharmacistResetPasswords(
    requestParameters: PostPharmacistResetPasswordsRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.postPharmacistResetPasswordsRaw(requestParameters, initOverrides);
  }

  /**
   * Register pharmacist
   */
  async postPharmacistsRaw(
    requestParameters: PostPharmacistsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacist>>> {
    if (
      requestParameters.pharmacyPharmacistRegistrable === null ||
      requestParameters.pharmacyPharmacistRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyPharmacistRegistrable",
        "Required parameter requestParameters.pharmacyPharmacistRegistrable was null or undefined when calling postPharmacists."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacists`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.pharmacyPharmacistRegistrable.map(PharmacyPharmacistRegistrableToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacistFromJSON));
  }

  /**
   * Register pharmacist
   */
  async postPharmacists(
    requestParameters: PostPharmacistsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacist>> {
    return promiseRetry(
      (retry) =>
        this.postPharmacistsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update pharmacist
   */
  async updatePharmacistRaw(
    requestParameters: UpdatePharmacistRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacist>> {
    if (requestParameters.pharmacistId === null || requestParameters.pharmacistId === undefined) {
      throw new runtime.RequiredError(
        "pharmacistId",
        "Required parameter requestParameters.pharmacistId was null or undefined when calling updatePharmacist."
      );
    }

    if (
      requestParameters.pharmacyPharmacistUpdatable === null ||
      requestParameters.pharmacyPharmacistUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyPharmacistUpdatable",
        "Required parameter requestParameters.pharmacyPharmacistUpdatable was null or undefined when calling updatePharmacist."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacists/{pharmacist_id}`.replace(
          `{${"pharmacist_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacistId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyPharmacistUpdatableToJSON(requestParameters.pharmacyPharmacistUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacistFromJSON(jsonValue));
  }

  /**
   * Update pharmacist
   */
  async updatePharmacist(
    requestParameters: UpdatePharmacistRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacist> {
    return promiseRetry(
      (retry) =>
        this.updatePharmacistRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
