import { WrapPageElementNodeArgs } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";

import { INDEXED_META, NO_INDEX_META } from "./lib/constants";
import { getIndexedLinkByPageType } from "./lib/getIndexedLinkByPageType";
import { getIndexedPageType } from "./lib/getIndexedPageType";

type WrapPageElement = (args: WrapPageElementNodeArgs) => React.ReactElement;

export const wrapPageElement: WrapPageElement = ({ element, props: { location, pageContext } }) => {
  const indexedPageType = getIndexedPageType(location.pathname);
  const indexedLink = getIndexedLinkByPageType({ indexedPageType, location, pageContext });

  if (indexedPageType === "NONE") {
    // 指定されていないページはnoindexとして扱う
    return (
      <React.Fragment>
        <Helmet meta={NO_INDEX_META} link={indexedLink} />
        {element}
      </React.Fragment>
    );
  }

  // 指定されているページはindex, followとして扱う
  return (
    <React.Fragment>
      <Helmet meta={INDEXED_META} link={indexedLink} />
      {element}
    </React.Fragment>
  );
};
