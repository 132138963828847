/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import { ClinicSlot, ClinicSlotFromJSON, ClinicSlotToJSON } from "../models";

export interface GetDepartmentSlotRequest {
  clinicId: number;
  departmentId: number;
  weekday: number;
}

export interface PutDepartmentSlotRequest {
  clinicId: number;
  departmentId: number;
  weekday: number;
  clinicSlot: Array<ClinicSlot>;
}

/**
 *
 */
export class SlotApi extends runtime.BaseAPI {
  /**
   * Get Clinic Counseling slots
   */
  async getDepartmentSlotRaw(
    requestParameters: GetDepartmentSlotRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ClinicSlot>>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling getDepartmentSlot."
      );
    }

    if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
      throw new runtime.RequiredError(
        "departmentId",
        "Required parameter requestParameters.departmentId was null or undefined when calling getDepartmentSlot."
      );
    }

    if (requestParameters.weekday === null || requestParameters.weekday === undefined) {
      throw new runtime.RequiredError(
        "weekday",
        "Required parameter requestParameters.weekday was null or undefined when calling getDepartmentSlot."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/departments/{department_id}/weekdays/{weekday}/slots`
          .replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters.clinicId)))
          .replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId)))
          .replace(`{${"weekday"}}`, encodeURIComponent(String(requestParameters.weekday))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicSlotFromJSON));
  }

  /**
   * Get Clinic Counseling slots
   */
  async getDepartmentSlot(
    requestParameters: GetDepartmentSlotRequest,
    initOverrides?: RequestInit
  ): Promise<Array<ClinicSlot>> {
    return promiseRetry(
      (retry) =>
        this.getDepartmentSlotRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register and Delete Clinic Counseling slots
   */
  async putDepartmentSlotRaw(
    requestParameters: PutDepartmentSlotRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ClinicSlot>>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling putDepartmentSlot."
      );
    }

    if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
      throw new runtime.RequiredError(
        "departmentId",
        "Required parameter requestParameters.departmentId was null or undefined when calling putDepartmentSlot."
      );
    }

    if (requestParameters.weekday === null || requestParameters.weekday === undefined) {
      throw new runtime.RequiredError(
        "weekday",
        "Required parameter requestParameters.weekday was null or undefined when calling putDepartmentSlot."
      );
    }

    if (requestParameters.clinicSlot === null || requestParameters.clinicSlot === undefined) {
      throw new runtime.RequiredError(
        "clinicSlot",
        "Required parameter requestParameters.clinicSlot was null or undefined when calling putDepartmentSlot."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/departments/{department_id}/weekdays/{weekday}/slots`
          .replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters.clinicId)))
          .replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId)))
          .replace(`{${"weekday"}}`, encodeURIComponent(String(requestParameters.weekday))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.clinicSlot.map(ClinicSlotToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicSlotFromJSON));
  }

  /**
   * Register and Delete Clinic Counseling slots
   */
  async putDepartmentSlot(
    requestParameters: PutDepartmentSlotRequest,
    initOverrides?: RequestInit
  ): Promise<Array<ClinicSlot>> {
    return promiseRetry(
      (retry) =>
        this.putDepartmentSlotRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
