/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  DeliveryCustomBadRequest,
  DeliveryCustomBadRequestFromJSON,
  DeliveryCustomBadRequestToJSON,
  DeliveryOrder,
  DeliveryOrderFromJSON,
  DeliveryOrderToJSON,
  GetOrdersResponse,
  GetOrdersResponseFromJSON,
  GetOrdersResponseToJSON,
  PatchOrderRequestBody,
  PatchOrderRequestBodyFromJSON,
  PatchOrderRequestBodyToJSON,
  PostOrderEventResponse,
  PostOrderEventResponseFromJSON,
  PostOrderEventResponseToJSON,
  PostOrderRequestBody,
  PostOrderRequestBodyFromJSON,
  PostOrderRequestBodyToJSON,
  PutOrderPatientRequestBody,
  PutOrderPatientRequestBodyFromJSON,
  PutOrderPatientRequestBodyToJSON,
} from "../models";

export interface DeleteOrderRequest {
  orderId: string;
}

export interface GetOrderRequest {
  orderId: string;
  requestToken?: string;
}

export interface GetOrdersRequest {
  from?: string;
  to?: string;
  counselingId?: number;
  lastEvent?: Array<GetOrdersLastEventEnum>;
  toName?: string;
  shortId?: string;
  orderBy?: GetOrdersOrderByEnum;
  limit?: number;
  offset?: number;
}

export interface PatchOrderRequest {
  orderId: string;
  patchOrderRequestBody: PatchOrderRequestBody;
}

export interface PostOrderRequest {
  postOrderRequestBody: PostOrderRequestBody;
}

export interface PostOrderEventsRequest {
  providerName: PostOrderEventsProviderNameEnum;
}

export interface PutOrderPatientRequest {
  orderId: string;
  putOrderPatientRequestBody: PutOrderPatientRequestBody;
}

/**
 *
 */
export class OrderApi extends runtime.BaseAPI {
  /**
   * Cancel a delivery order
   */
  async deleteOrderRaw(
    requestParameters: DeleteOrderRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling deleteOrder."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/orders/{order_id}`.replace(`{${"order_id"}}`, encodeURIComponent(String(requestParameters.orderId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Cancel a delivery order
   */
  async deleteOrder(requestParameters: DeleteOrderRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteOrderRaw(requestParameters, initOverrides);
  }

  /**
   * Get a delivery order
   */
  async getOrderRaw(
    requestParameters: GetOrderRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeliveryOrder>> {
    if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling getOrder."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.requestToken !== undefined) {
      queryParameters["request_token"] = requestParameters.requestToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/orders/{order_id}`.replace(`{${"order_id"}}`, encodeURIComponent(String(requestParameters.orderId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryOrderFromJSON(jsonValue));
  }

  /**
   * Get a delivery order
   */
  async getOrder(requestParameters: GetOrderRequest, initOverrides?: RequestInit): Promise<DeliveryOrder> {
    return promiseRetry(
      (retry) =>
        this.getOrderRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Search for delivery orders by multiple conditions
   */
  async getOrdersRaw(
    requestParameters: GetOrdersRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetOrdersResponse>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.counselingId !== undefined) {
      queryParameters["counseling_id"] = requestParameters.counselingId;
    }

    if (requestParameters.lastEvent) {
      queryParameters["last_event"] = requestParameters.lastEvent;
    }

    if (requestParameters.toName !== undefined) {
      queryParameters["to_name"] = requestParameters.toName;
    }

    if (requestParameters.shortId !== undefined) {
      queryParameters["short_id"] = requestParameters.shortId;
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["order_by"] = requestParameters.orderBy;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/orders`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetOrdersResponseFromJSON(jsonValue));
  }

  /**
   * Search for delivery orders by multiple conditions
   */
  async getOrders(requestParameters: GetOrdersRequest, initOverrides?: RequestInit): Promise<GetOrdersResponse> {
    return promiseRetry(
      (retry) =>
        this.getOrdersRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update a delivery order
   */
  async patchOrderRaw(
    requestParameters: PatchOrderRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeliveryOrder>> {
    if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling patchOrder."
      );
    }

    if (requestParameters.patchOrderRequestBody === null || requestParameters.patchOrderRequestBody === undefined) {
      throw new runtime.RequiredError(
        "patchOrderRequestBody",
        "Required parameter requestParameters.patchOrderRequestBody was null or undefined when calling patchOrder."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/orders/{order_id}`.replace(`{${"order_id"}}`, encodeURIComponent(String(requestParameters.orderId))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchOrderRequestBodyToJSON(requestParameters.patchOrderRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryOrderFromJSON(jsonValue));
  }

  /**
   * Update a delivery order
   */
  async patchOrder(requestParameters: PatchOrderRequest, initOverrides?: RequestInit): Promise<DeliveryOrder> {
    return promiseRetry(
      (retry) =>
        this.patchOrderRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register a delivery order
   */
  async postOrderRaw(
    requestParameters: PostOrderRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeliveryOrder>> {
    if (requestParameters.postOrderRequestBody === null || requestParameters.postOrderRequestBody === undefined) {
      throw new runtime.RequiredError(
        "postOrderRequestBody",
        "Required parameter requestParameters.postOrderRequestBody was null or undefined when calling postOrder."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/orders`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostOrderRequestBodyToJSON(requestParameters.postOrderRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryOrderFromJSON(jsonValue));
  }

  /**
   * Register a delivery order
   */
  async postOrder(requestParameters: PostOrderRequest, initOverrides?: RequestInit): Promise<DeliveryOrder> {
    return promiseRetry(
      (retry) =>
        this.postOrderRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Accept provider\'s order events
   */
  async postOrderEventsRaw(
    requestParameters: PostOrderEventsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PostOrderEventResponse>> {
    if (requestParameters.providerName === null || requestParameters.providerName === undefined) {
      throw new runtime.RequiredError(
        "providerName",
        "Required parameter requestParameters.providerName was null or undefined when calling postOrderEvents."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/providers/{provider_name}/order-events`.replace(
          `{${"provider_name"}}`,
          encodeURIComponent(String(requestParameters.providerName))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PostOrderEventResponseFromJSON(jsonValue));
  }

  /**
   * Accept provider\'s order events
   */
  async postOrderEvents(
    requestParameters: PostOrderEventsRequest,
    initOverrides?: RequestInit
  ): Promise<PostOrderEventResponse> {
    return promiseRetry(
      (retry) =>
        this.postOrderEventsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Replace patient information using JWT
   */
  async putOrderPatientRaw(
    requestParameters: PutOrderPatientRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<DeliveryOrder>> {
    if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
      throw new runtime.RequiredError(
        "orderId",
        "Required parameter requestParameters.orderId was null or undefined when calling putOrderPatient."
      );
    }

    if (
      requestParameters.putOrderPatientRequestBody === null ||
      requestParameters.putOrderPatientRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putOrderPatientRequestBody",
        "Required parameter requestParameters.putOrderPatientRequestBody was null or undefined when calling putOrderPatient."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/orders/{order_id}/patient`.replace(
          `{${"order_id"}}`,
          encodeURIComponent(String(requestParameters.orderId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutOrderPatientRequestBodyToJSON(requestParameters.putOrderPatientRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => DeliveryOrderFromJSON(jsonValue));
  }

  /**
   * Replace patient information using JWT
   */
  async putOrderPatient(
    requestParameters: PutOrderPatientRequest,
    initOverrides?: RequestInit
  ): Promise<DeliveryOrder> {
    return promiseRetry(
      (retry) =>
        this.putOrderPatientRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetOrdersLastEventEnum {
  Registered = "registered",
  Requested = "requested",
  Accepted = "accepted",
  Started = "started",
  Departed = "departed",
  Arrived = "arrived",
  Finished = "finished",
  Canceled = "canceled",
  Rejected = "rejected",
  Absence = "absence",
  Returned = "returned",
}
/**
 * @export
 * @enum {string}
 */
export enum GetOrdersOrderByEnum {
  Time = "requested_time",
  TimeDesc = "requested_time_desc",
}
/**
 * @export
 * @enum {string}
 */
export enum PostOrderEventsProviderNameEnum {
  Cbcloud = "cbcloud",
  Wolt = "wolt",
  Yamato = "yamato",
}
