/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX bff-patient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  BffPatientCustomBadRequest2,
  BffPatientCustomBadRequest2FromJSON,
  BffPatientCustomBadRequest2ToJSON,
  BffPatientMCCMJAHISSyncJob,
  BffPatientMCCMJAHISSyncJobFromJSON,
  BffPatientMCCMJAHISSyncJobToJSON,
  PostMCCMJAHISSyncJobRequestBody,
  PostMCCMJAHISSyncJobRequestBodyFromJSON,
  PostMCCMJAHISSyncJobRequestBodyToJSON,
} from "../models";

export interface RegisterMCCMJAHISSyncJobsRequest {
  chainId: number;
  postMCCMJAHISSyncJobRequestBody: PostMCCMJAHISSyncJobRequestBody;
}

/**
 *
 */
export class MccmJahisSyncJobApi extends runtime.BaseAPI {
  /**
   * Register MCCM sync medicine notebook JAHIS jobs of the patient with family account.
   */
  async registerMCCMJAHISSyncJobsRaw(
    requestParameters: RegisterMCCMJAHISSyncJobsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<BffPatientMCCMJAHISSyncJob>>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling registerMCCMJAHISSyncJobs."
      );
    }

    if (
      requestParameters.postMCCMJAHISSyncJobRequestBody === null ||
      requestParameters.postMCCMJAHISSyncJobRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postMCCMJAHISSyncJobRequestBody",
        "Required parameter requestParameters.postMCCMJAHISSyncJobRequestBody was null or undefined when calling registerMCCMJAHISSyncJobs."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/mccm-jahis-sync-jobs`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostMCCMJAHISSyncJobRequestBodyToJSON(requestParameters.postMCCMJAHISSyncJobRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BffPatientMCCMJAHISSyncJobFromJSON));
  }

  /**
   * Register MCCM sync medicine notebook JAHIS jobs of the patient with family account.
   */
  async registerMCCMJAHISSyncJobs(
    requestParameters: RegisterMCCMJAHISSyncJobsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<BffPatientMCCMJAHISSyncJob>> {
    return promiseRetry(
      (retry) =>
        this.registerMCCMJAHISSyncJobsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
