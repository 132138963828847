/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  AdminOperator,
  AdminOperatorFromJSON,
  AdminOperatorToJSON,
  AdminOperators,
  AdminOperatorsFromJSON,
  AdminOperatorsToJSON,
  AdminPostOperatorValue,
  AdminPostOperatorValueFromJSON,
  AdminPostOperatorValueToJSON,
  PatchOperatorRequestBody,
  PatchOperatorRequestBodyFromJSON,
  PatchOperatorRequestBodyToJSON,
} from "../models";

export interface CreateOperatorRequest {
  adminPostOperatorValue: Array<AdminPostOperatorValue>;
}

export interface GetOperatorRequest {
  email: string;
}

export interface UpdateOperatorRequest {
  patchOperatorRequestBody: PatchOperatorRequestBody;
}

/**
 *
 */
export class OperatorApi extends runtime.BaseAPI {
  /**
   * Register operator
   */
  async createOperatorRaw(
    requestParameters: CreateOperatorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminOperator>>> {
    if (requestParameters.adminPostOperatorValue === null || requestParameters.adminPostOperatorValue === undefined) {
      throw new runtime.RequiredError(
        "adminPostOperatorValue",
        "Required parameter requestParameters.adminPostOperatorValue was null or undefined when calling createOperator."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/operators`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.adminPostOperatorValue.map(AdminPostOperatorValueToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminOperatorFromJSON));
  }

  /**
   * Register operator
   */
  async createOperator(
    requestParameters: CreateOperatorRequest,
    initOverrides?: RequestInit
  ): Promise<Array<AdminOperator>> {
    return promiseRetry(
      (retry) =>
        this.createOperatorRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Delete operator
   */
  async deleteOperatorRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operator`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete operator
   */
  async deleteOperator(initOverrides?: RequestInit): Promise<void> {
    await this.deleteOperatorRaw(initOverrides);
  }

  /**
   * Get operator
   */
  async getOperatorRaw(
    requestParameters: GetOperatorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminOperator>>> {
    if (requestParameters.email === null || requestParameters.email === undefined) {
      throw new runtime.RequiredError(
        "email",
        "Required parameter requestParameters.email was null or undefined when calling getOperator."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.email !== undefined) {
      queryParameters["email"] = requestParameters.email;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/operator`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminOperatorFromJSON));
  }

  /**
   * Get operator
   */
  async getOperator(requestParameters: GetOperatorRequest, initOverrides?: RequestInit): Promise<Array<AdminOperator>> {
    return promiseRetry(
      (retry) =>
        this.getOperatorRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get operators
   */
  async getOperatorsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminOperators>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/operators`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AdminOperatorsFromJSON(jsonValue));
  }

  /**
   * Get operators
   */
  async getOperators(initOverrides?: RequestInit): Promise<AdminOperators> {
    return promiseRetry(
      (retry) =>
        this.getOperatorsRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update operator
   */
  async updateOperatorRaw(
    requestParameters: UpdateOperatorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AdminOperator>> {
    if (
      requestParameters.patchOperatorRequestBody === null ||
      requestParameters.patchOperatorRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchOperatorRequestBody",
        "Required parameter requestParameters.patchOperatorRequestBody was null or undefined when calling updateOperator."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operator`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchOperatorRequestBodyToJSON(requestParameters.patchOperatorRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AdminOperatorFromJSON(jsonValue));
  }

  /**
   * Update operator
   */
  async updateOperator(requestParameters: UpdateOperatorRequest, initOverrides?: RequestInit): Promise<AdminOperator> {
    return promiseRetry(
      (retry) =>
        this.updateOperatorRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
