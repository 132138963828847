/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  GetCounselingCountResponse,
  GetCounselingCountResponseFromJSON,
  GetCounselingCountResponseToJSON,
} from "../models";

export interface GetCounselingsCountsRequest {
  from?: string;
  to?: string;
  requestedFrom?: string;
  requestedTo?: string;
  pharmacistId?: number;
  patientId?: number;
  pharmacyId?: number;
  keyword?: string;
  familyAccountId?: number;
  followUpStatus?: Array<GetCounselingsCountsFollowUpStatusEnum>;
  followUpQuestionnairesRegistered?: boolean;
  followUpNotificationTimeFrom?: string;
  followUpNotificationTimeTo?: string;
  followUpIsNotifyAnswered?: boolean;
  status?: Array<GetCounselingsCountsStatusEnum>;
  billingStatus?: GetCounselingsCountsBillingStatusEnum;
  dispensingStatus?: Array<GetCounselingsCountsDispensingStatusEnum>;
  limit?: number;
  offset?: number;
  orderBy?: GetCounselingsCountsOrderByEnum;
  type?: Array<GetCounselingsCountsTypeEnum>;
  requestType?: GetCounselingsCountsRequestTypeEnum;
  autoLinkStatus?: Array<GetCounselingsCountsAutoLinkStatusEnum>;
  acceptCode?: number;
  dateOfBirth?: string;
}

/**
 *
 */
export class CounselingCountsApi extends runtime.BaseAPI {
  /**
   * Get counseling counts
   */
  async getCounselingsCountsRaw(
    requestParameters: GetCounselingsCountsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetCounselingCountResponse>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.requestedFrom !== undefined) {
      queryParameters["requested_from"] = moment(requestParameters.requestedFrom).tz("Asia/Tokyo").format();
    }

    if (requestParameters.requestedTo !== undefined) {
      queryParameters["requested_to"] = moment(requestParameters.requestedTo).tz("Asia/Tokyo").format();
    }

    if (requestParameters.pharmacistId !== undefined) {
      queryParameters["pharmacist_id"] = requestParameters.pharmacistId;
    }

    if (requestParameters.patientId !== undefined) {
      queryParameters["patient_id"] = requestParameters.patientId;
    }

    if (requestParameters.pharmacyId !== undefined) {
      queryParameters["pharmacy_id"] = requestParameters.pharmacyId;
    }

    if (requestParameters.keyword !== undefined) {
      queryParameters["keyword"] = requestParameters.keyword;
    }

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.followUpStatus) {
      queryParameters["follow_up_status"] = requestParameters.followUpStatus;
    }

    if (requestParameters.followUpQuestionnairesRegistered !== undefined) {
      queryParameters["follow_up_questionnaires_registered"] = requestParameters.followUpQuestionnairesRegistered;
    }

    if (requestParameters.followUpNotificationTimeFrom !== undefined) {
      queryParameters["follow_up_notification_time_from"] = moment(requestParameters.followUpNotificationTimeFrom)
        .tz("Asia/Tokyo")
        .format();
    }

    if (requestParameters.followUpNotificationTimeTo !== undefined) {
      queryParameters["follow_up_notification_time_to"] = moment(requestParameters.followUpNotificationTimeTo)
        .tz("Asia/Tokyo")
        .format();
    }

    if (requestParameters.followUpIsNotifyAnswered !== undefined) {
      queryParameters["follow_up_is_notify_answered"] = requestParameters.followUpIsNotifyAnswered;
    }

    if (requestParameters.status) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.billingStatus !== undefined) {
      queryParameters["billing_status"] = requestParameters.billingStatus;
    }

    if (requestParameters.dispensingStatus) {
      queryParameters["dispensing_status"] = requestParameters.dispensingStatus;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["order_by"] = requestParameters.orderBy;
    }

    if (requestParameters.type) {
      queryParameters["type"] = requestParameters.type;
    }

    if (requestParameters.requestType !== undefined) {
      queryParameters["request_type"] = requestParameters.requestType;
    }

    if (requestParameters.autoLinkStatus) {
      queryParameters["auto_link_status"] = requestParameters.autoLinkStatus;
    }

    if (requestParameters.acceptCode !== undefined) {
      queryParameters["accept_code"] = requestParameters.acceptCode;
    }

    if (requestParameters.dateOfBirth !== undefined) {
      queryParameters["date_of_birth"] = requestParameters.dateOfBirth;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/counts`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCounselingCountResponseFromJSON(jsonValue));
  }

  /**
   * Get counseling counts
   */
  async getCounselingsCounts(
    requestParameters: GetCounselingsCountsRequest,
    initOverrides?: RequestInit
  ): Promise<GetCounselingCountResponse> {
    return promiseRetry(
      (retry) =>
        this.getCounselingsCountsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsCountsFollowUpStatusEnum {
  Initialized = "initialized",
  Registered = "registered",
  Notified = "notified",
  Answered = "answered",
  AnswerConfirmed = "answer_confirmed",
  Completed = "completed",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsCountsStatusEnum {
  Requested = "requested",
  Canceled = "canceled",
  Accepted = "accepted",
  Waiting = "waiting",
  CounselingStarted = "counseling_started",
  CounselingCompleted = "counseling_completed",
  BillingProcessing = "billing_processing",
  BillingCompleted = "billing_completed",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsCountsBillingStatusEnum {
  Initialized = "initialized",
  Registered = "registered",
  Started = "started",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsCountsDispensingStatusEnum {
  Initialized = "initialized",
  Dispensed = "dispensed",
  Notified = "notified",
  Completed = "completed",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsCountsOrderByEnum {
  Date = "date",
  DateDesc = "date_desc",
  FollowUpNotificationDate = "follow_up_notification_date",
  FollowUpNotificationDateDesc = "follow_up_notification_date_desc",
  FollowUpAnsweredDate = "follow_up_answered_date",
  FollowUpAnsweredDateDesc = "follow_up_answered_date_desc",
  RequestedTime = "requested_time",
  RequestedTimeDesc = "requested_time_desc",
  AcceptedTime = "accepted_time",
  AcceptedTimeDesc = "accepted_time_desc",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsCountsTypeEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  FollowUpOnly = "follow_up_only",
  DeliveryOnly = "delivery_only",
  DirectVisit = "direct_visit",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsCountsRequestTypeEnum {
  Web = "web",
  Line = "line",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsCountsAutoLinkStatusEnum {
  Initialized = "initialized",
  Completed = "completed",
  Canceled = "canceled",
}
