/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatientCustomBadRequest2,
  PatientCustomBadRequest2FromJSON,
  PatientCustomBadRequest2ToJSON,
  PutReceiptCodesRequestBody,
  PutReceiptCodesRequestBodyFromJSON,
  PutReceiptCodesRequestBodyToJSON,
  PutReceiptCodesResponse,
  PutReceiptCodesResponseFromJSON,
  PutReceiptCodesResponseToJSON,
} from "../models";

export interface DeleteReceiptCodesRequest {
  patientId: number;
  pharmacyId: number;
  familyAccountId?: number;
}

export interface PostReceiptCodesRequest {
  patientId: number;
  pharmacyId: number;
  putReceiptCodesRequestBody: PutReceiptCodesRequestBody;
}

/**
 *
 */
export class ReceiptCodeApi extends runtime.BaseAPI {
  /**
   * Delete form patient receipt code
   */
  async deleteReceiptCodesRaw(
    requestParameters: DeleteReceiptCodesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling deleteReceiptCodes."
      );
    }

    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling deleteReceiptCodes."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/receipt-codes/{pharmacy_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete form patient receipt code
   */
  async deleteReceiptCodes(requestParameters: DeleteReceiptCodesRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteReceiptCodesRaw(requestParameters, initOverrides);
  }

  /**
   * register and update form patient receipt code
   */
  async postReceiptCodesRaw(
    requestParameters: PostReceiptCodesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PutReceiptCodesResponse>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling postReceiptCodes."
      );
    }

    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling postReceiptCodes."
      );
    }

    if (
      requestParameters.putReceiptCodesRequestBody === null ||
      requestParameters.putReceiptCodesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putReceiptCodesRequestBody",
        "Required parameter requestParameters.putReceiptCodesRequestBody was null or undefined when calling postReceiptCodes."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/receipt-codes/{pharmacy_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutReceiptCodesRequestBodyToJSON(requestParameters.putReceiptCodesRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PutReceiptCodesResponseFromJSON(jsonValue));
  }

  /**
   * register and update form patient receipt code
   */
  async postReceiptCodes(
    requestParameters: PostReceiptCodesRequest,
    initOverrides?: RequestInit
  ): Promise<PutReceiptCodesResponse> {
    return promiseRetry(
      (retry) =>
        this.postReceiptCodesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
