/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PharmacyCounselorPublicAcceptCode,
  PharmacyCounselorPublicAcceptCodeFromJSON,
  PharmacyCounselorPublicAcceptCodeToJSON,
} from "../models";

export interface GetAcceptCodesRequest {
  pharmacyId: number;
  type?: GetAcceptCodesTypeEnum;
}

/**
 *
 */
export class CounselingAcceptCodeApi extends runtime.BaseAPI {
  /**
   * No authentication required, returns publicly available information
   */
  async getAcceptCodesRaw(
    requestParameters: GetAcceptCodesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyCounselorPublicAcceptCode>>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getAcceptCodes."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.pharmacyId !== undefined) {
      queryParameters["pharmacy_id"] = requestParameters.pharmacyId;
    }

    if (requestParameters.type !== undefined) {
      queryParameters["type"] = requestParameters.type;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/accept-codes`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PharmacyCounselorPublicAcceptCodeFromJSON)
    );
  }

  /**
   * No authentication required, returns publicly available information
   */
  async getAcceptCodes(
    requestParameters: GetAcceptCodesRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyCounselorPublicAcceptCode>> {
    return promiseRetry(
      (retry) =>
        this.getAcceptCodesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetAcceptCodesTypeEnum {
  Preparing = "preparing",
  Notified = "notified",
}
