import clsx from "clsx";
import React from "react";

import * as styles from "./BaseButton.module.css";

export const variants = [
  "primary",
  "primary-active",
  "primary-weak",
  "primary-weak-key",
  "secondary",
  "tertiary",
  "tertiary-light",
  "tertiary-link",
  "select",
  "unselect",
  "select-light",
  "unselect-light",
  "text",
  "text-link",
  "text-inner-link",
  "paragraph-light",
  "paragraph-primary",
  "paragraph-link",
  "paragraph-mixed",
  "paragraph-pickup",
  "paragraph",
  "paragraph-weak",
  "icon",
  "caution",
  "oval-primary",
  "oval-secondary-key",
  "oval-secondary",
  "oval-normal",
  "oval-weak",
] as const;
export const fontSizes = ["s", "m", "xm"] as const;

export const iconPositioms = ["left", "right"] as const;

export type Props = React.PropsWithChildren<{
  variant?: (typeof variants)[number];
  fontSize?: (typeof fontSizes)[number];
  iconPosition?: (typeof iconPositioms)[number];
  fullWidth?: boolean;
  stretchWidth?: boolean;
  stretchHeight?: boolean;
  fitInputHeight?: boolean;
  fullViewportWidth?: boolean;
  small?: boolean;
  large?: boolean;
  disabled?: boolean;
  responsive?: boolean;
  fab?: boolean;
  menu?: boolean;
  className?: string;
}>;

export type Ref = HTMLDivElement;

/**
 * @deprecated FilledButton, OutlinedButtonなどの新しいボタンコンポーネントを使うか、個別にボタンのスタイルを定義してください。
 */
export default React.forwardRef<Ref, Props>(
  (
    {
      variant,
      fontSize,
      iconPosition,
      fullWidth,
      fullViewportWidth,
      stretchWidth,
      stretchHeight,
      small,
      large,
      disabled,
      responsive,
      fab,
      menu,
      className,
      children,
      ...props
    }: Props,
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          styles.base,
          variant === "primary" && styles.primary,
          variant === "primary-active" && styles.primaryActive,
          variant === "primary-weak" && styles.primaryWeak,
          variant === "primary-weak-key" && styles.primaryWeakKey,
          variant === "secondary" && styles.secondary,
          variant === "tertiary" && styles.tertiary,
          variant === "tertiary-light" && styles.tertiaryLight,
          variant === "tertiary-link" && styles.tertiaryLink,
          variant === "select" && styles.select,
          variant === "unselect" && styles.unselect,
          variant === "select-light" && styles.selectLight,
          variant === "unselect-light" && styles.unselectLight,
          variant === "text" && styles.text,
          variant === "text-link" && styles.textLink,
          variant === "text-inner-link" && styles.textInnerLink,
          variant === "paragraph-light" && styles.paragraphLight,
          variant === "paragraph-primary" && styles.paragraphPrimary,
          variant === "paragraph-link" && styles.paragraphLink,
          variant === "paragraph" && styles.paragraph,
          variant === "paragraph-weak" && styles.paragraphWeak,
          variant === "paragraph-mixed" && styles.paragraphMixed,
          variant === "paragraph-pickup" && styles.paragraphPickUp,
          variant === "caution" && styles.caution,
          variant === "oval-primary" && styles.ovalPrimary,
          variant === "oval-secondary-key" && styles.ovalSecondaryKey,
          variant === "oval-secondary" && styles.ovalSecondary,
          variant === "oval-normal" && styles.ovalNormal,
          variant === "oval-weak" && styles.ovalWeak,
          fullWidth && styles.fullWidth,
          fullViewportWidth && styles.fullViewportWidth,
          stretchWidth && styles.stretchWidth,
          stretchHeight && styles.stretchHeight,
          small && styles.small,
          large && styles.large,
          disabled && styles.disabled,
          responsive && styles.responsive,
          fontSize === "s" && styles.fontSizeS,
          fontSize === "m" && styles.fontSizeM,
          fontSize === "xm" && styles.fontSizeXM,
          !fab && iconPosition === "left" && styles.leftIcon,
          !fab && iconPosition === "right" && styles.rightIcon,
          fab && styles.fab,
          menu && styles.menu,
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);
