/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  ClinicCustomForbiddenError,
  ClinicCustomForbiddenErrorFromJSON,
  ClinicCustomForbiddenErrorToJSON,
  ClinicDoctor,
  ClinicDoctorFromJSON,
  ClinicDoctorToJSON,
  ClinicDoctorRegistrable,
  ClinicDoctorRegistrableFromJSON,
  ClinicDoctorRegistrableToJSON,
  ClinicDoctorUpdatable,
  ClinicDoctorUpdatableFromJSON,
  ClinicDoctorUpdatableToJSON,
  ClinicDoctors,
  ClinicDoctorsFromJSON,
  ClinicDoctorsToJSON,
  PostDoctorResetPasswordRequestBody,
  PostDoctorResetPasswordRequestBodyFromJSON,
  PostDoctorResetPasswordRequestBodyToJSON,
  PutDoctorPasswordRequestBody,
  PutDoctorPasswordRequestBodyFromJSON,
  PutDoctorPasswordRequestBodyToJSON,
} from "../models";

export interface DeleteDoctorRequest {
  doctorId: number;
}

export interface GetDoctorRequest {
  doctorId: number;
}

export interface GetDoctorsRequest {
  id?: Array<number>;
  clinicId?: number;
  limit?: number;
  offset?: number;
}

export interface PatchDoctorRequest {
  doctorId: number;
  clinicDoctorUpdatable: ClinicDoctorUpdatable;
}

export interface PostDoctorRequest {
  clinicDoctorRegistrable: Array<ClinicDoctorRegistrable>;
}

export interface PostDoctorResetPasswordRequest {
  postDoctorResetPasswordRequestBody: PostDoctorResetPasswordRequestBody;
}

export interface PutDoctorPasswordRequest {
  putDoctorPasswordRequestBody: PutDoctorPasswordRequestBody;
}

/**
 *
 */
export class DoctorApi extends runtime.BaseAPI {
  /**
   * Delete doctor
   */
  async deleteDoctorRaw(
    requestParameters: DeleteDoctorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.doctorId === null || requestParameters.doctorId === undefined) {
      throw new runtime.RequiredError(
        "doctorId",
        "Required parameter requestParameters.doctorId was null or undefined when calling deleteDoctor."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/doctors/{doctor_id}`.replace(
          `{${"doctor_id"}}`,
          encodeURIComponent(String(requestParameters.doctorId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete doctor
   */
  async deleteDoctor(requestParameters: DeleteDoctorRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteDoctorRaw(requestParameters, initOverrides);
  }

  /**
   * Logout doctor by auth_id from JWT
   */
  async deleteDoctorSessionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/doctor/session`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Logout doctor by auth_id from JWT
   */
  async deleteDoctorSession(initOverrides?: RequestInit): Promise<void> {
    await this.deleteDoctorSessionRaw(initOverrides);
  }

  /**
   * Get doctor by id
   */
  async getDoctorRaw(
    requestParameters: GetDoctorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicDoctor>> {
    if (requestParameters.doctorId === null || requestParameters.doctorId === undefined) {
      throw new runtime.RequiredError(
        "doctorId",
        "Required parameter requestParameters.doctorId was null or undefined when calling getDoctor."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/doctors/{doctor_id}`.replace(
          `{${"doctor_id"}}`,
          encodeURIComponent(String(requestParameters.doctorId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicDoctorFromJSON(jsonValue));
  }

  /**
   * Get doctor by id
   */
  async getDoctor(requestParameters: GetDoctorRequest, initOverrides?: RequestInit): Promise<ClinicDoctor> {
    return promiseRetry(
      (retry) =>
        this.getDoctorRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get doctor by auth_id from JWT
   */
  async getDoctorByAuthIDRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClinicDoctor>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/doctor`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicDoctorFromJSON(jsonValue));
  }

  /**
   * Get doctor by auth_id from JWT
   */
  async getDoctorByAuthID(initOverrides?: RequestInit): Promise<ClinicDoctor> {
    return promiseRetry(
      (retry) =>
        this.getDoctorByAuthIDRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Search doctor by condition
   */
  async getDoctorsRaw(
    requestParameters: GetDoctorsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicDoctors>> {
    const queryParameters: any = {};

    if (requestParameters.id) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.clinicId !== undefined) {
      queryParameters["clinic_id"] = requestParameters.clinicId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/doctors`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicDoctorsFromJSON(jsonValue));
  }

  /**
   * Search doctor by condition
   */
  async getDoctors(requestParameters: GetDoctorsRequest, initOverrides?: RequestInit): Promise<ClinicDoctors> {
    return promiseRetry(
      (retry) =>
        this.getDoctorsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update doctor
   */
  async patchDoctorRaw(
    requestParameters: PatchDoctorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicDoctor>> {
    if (requestParameters.doctorId === null || requestParameters.doctorId === undefined) {
      throw new runtime.RequiredError(
        "doctorId",
        "Required parameter requestParameters.doctorId was null or undefined when calling patchDoctor."
      );
    }

    if (requestParameters.clinicDoctorUpdatable === null || requestParameters.clinicDoctorUpdatable === undefined) {
      throw new runtime.RequiredError(
        "clinicDoctorUpdatable",
        "Required parameter requestParameters.clinicDoctorUpdatable was null or undefined when calling patchDoctor."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/doctors/{doctor_id}`.replace(
          `{${"doctor_id"}}`,
          encodeURIComponent(String(requestParameters.doctorId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: ClinicDoctorUpdatableToJSON(requestParameters.clinicDoctorUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicDoctorFromJSON(jsonValue));
  }

  /**
   * Update doctor
   */
  async patchDoctor(requestParameters: PatchDoctorRequest, initOverrides?: RequestInit): Promise<ClinicDoctor> {
    return promiseRetry(
      (retry) =>
        this.patchDoctorRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register doctor
   */
  async postDoctorRaw(
    requestParameters: PostDoctorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ClinicDoctor>>> {
    if (requestParameters.clinicDoctorRegistrable === null || requestParameters.clinicDoctorRegistrable === undefined) {
      throw new runtime.RequiredError(
        "clinicDoctorRegistrable",
        "Required parameter requestParameters.clinicDoctorRegistrable was null or undefined when calling postDoctor."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/doctors`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.clinicDoctorRegistrable.map(ClinicDoctorRegistrableToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicDoctorFromJSON));
  }

  /**
   * Register doctor
   */
  async postDoctor(requestParameters: PostDoctorRequest, initOverrides?: RequestInit): Promise<Array<ClinicDoctor>> {
    return promiseRetry(
      (retry) =>
        this.postDoctorRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Send Reset password email
   */
  async postDoctorResetPasswordRaw(
    requestParameters: PostDoctorResetPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.postDoctorResetPasswordRequestBody === null ||
      requestParameters.postDoctorResetPasswordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postDoctorResetPasswordRequestBody",
        "Required parameter requestParameters.postDoctorResetPasswordRequestBody was null or undefined when calling postDoctorResetPassword."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/doctor-reset-passwords`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostDoctorResetPasswordRequestBodyToJSON(requestParameters.postDoctorResetPasswordRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send Reset password email
   */
  async postDoctorResetPassword(
    requestParameters: PostDoctorResetPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.postDoctorResetPasswordRaw(requestParameters, initOverrides);
  }

  /**
   * Accept password reset complete by auth_id from JWT
   */
  async postDoctorResetPasswordCompletionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/doctor/reset-password-completions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accept password reset complete by auth_id from JWT
   */
  async postDoctorResetPasswordCompletions(initOverrides?: RequestInit): Promise<void> {
    await this.postDoctorResetPasswordCompletionsRaw(initOverrides);
  }

  /**
   * Update own password with auth_id in JWT
   */
  async putDoctorPasswordRaw(
    requestParameters: PutDoctorPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicDoctor>> {
    if (
      requestParameters.putDoctorPasswordRequestBody === null ||
      requestParameters.putDoctorPasswordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putDoctorPasswordRequestBody",
        "Required parameter requestParameters.putDoctorPasswordRequestBody was null or undefined when calling putDoctorPassword."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/doctor/password`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutDoctorPasswordRequestBodyToJSON(requestParameters.putDoctorPasswordRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicDoctorFromJSON(jsonValue));
  }

  /**
   * Update own password with auth_id in JWT
   */
  async putDoctorPassword(
    requestParameters: PutDoctorPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<ClinicDoctor> {
    return promiseRetry(
      (retry) =>
        this.putDoctorPasswordRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
