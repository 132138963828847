import AccountIcon from "@mgdx/assets/icons/AccountIcon";
import ClinicIcon from "@mgdx/assets/icons/ClinicIcon";
import StorefrontIcon from "@mgdx/assets/icons/StorefrontIcon";
import { Situation } from "@mgdx/shared/src/models/MedicalCounseling";
import clsx from "clsx";
import React, { ImgHTMLAttributes } from "react";

import * as styles from "./Avatar.module.css";

export type Props = {
  situation: Situation | "image";
  large?: boolean;
  imageProps?: ImgHTMLAttributes<HTMLImageElement>;
  onClick?: () => void;
};

const Avatar = ({ situation, large, imageProps, onClick }: Props) => {
  if (situation === "patient") {
    return (
      <div
        role={onClick ? "button" : undefined}
        className={clsx(styles.avatar, large && styles.large, onClick && styles.clickable, styles.patientAvatar)}
        onClick={onClick}
        onKeyDown={(e) => {
          if (onClick && e.key === "Enter") onClick();
        }}
        tabIndex={onClick ? 0 : undefined}
      >
        <AccountIcon />
      </div>
    );
  }

  if (situation === "clinic") {
    return (
      <div
        role={onClick ? "button" : undefined}
        className={clsx(styles.avatar, large && styles.large, onClick && styles.clickable, styles.clinicAvatar)}
        onClick={onClick}
        onKeyDown={(e) => {
          if (onClick && e.key === "Enter") onClick();
        }}
        tabIndex={onClick ? 0 : undefined}
      >
        <ClinicIcon />
      </div>
    );
  }

  if (situation === "pharmacy") {
    return (
      <div
        role={onClick ? "button" : undefined}
        className={clsx(styles.avatar, large && styles.large, onClick && styles.clickable, styles.pharmacyAvatar)}
        onClick={onClick}
        onKeyDown={(e) => {
          if (onClick && e.key === "Enter") onClick();
        }}
        tabIndex={onClick ? 0 : undefined}
      >
        <StorefrontIcon size="xm" />
      </div>
    );
  }

  if (situation === "image") {
    return (
      <div
        role={onClick ? "button" : undefined}
        className={clsx(styles.avatar, large && styles.large, onClick && styles.clickable, styles.imageAvatar)}
        onClick={onClick}
        onKeyDown={(e) => {
          if (onClick && e.key === "Enter") onClick();
        }}
        tabIndex={onClick ? 0 : undefined}
      >
        <div className={styles.mask} />
        <img alt="プロフィール画像" {...imageProps} />
      </div>
    );
  }

  return null;
};

export default Avatar;
