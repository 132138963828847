/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PharmacyCounselorCustomEntityTooLarge,
  PharmacyCounselorCustomEntityTooLargeFromJSON,
  PharmacyCounselorCustomEntityTooLargeToJSON,
  PharmacyCounselorPrescription,
  PharmacyCounselorPrescriptionFromJSON,
  PharmacyCounselorPrescriptionToJSON,
  PharmacyCounselorPrescriptionRegistrable,
  PharmacyCounselorPrescriptionRegistrableFromJSON,
  PharmacyCounselorPrescriptionRegistrableToJSON,
} from "../models";

export interface DeletePrescriptionRequest {
  counselingId: number;
  file: string;
}

export interface PostPrescriptionRequest {
  counselingId: number;
  pharmacyCounselorPrescriptionRegistrable: PharmacyCounselorPrescriptionRegistrable;
}

export interface PutPrescriptionRequest {
  counselingId: number;
  pharmacyCounselorPrescriptionRegistrable: PharmacyCounselorPrescriptionRegistrable;
}

/**
 *
 */
export class PrescriptionApi extends runtime.BaseAPI {
  /**
   * Delete prescription images
   */
  async deletePrescriptionRaw(
    requestParameters: DeletePrescriptionRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling deletePrescription."
      );
    }

    if (requestParameters.file === null || requestParameters.file === undefined) {
      throw new runtime.RequiredError(
        "file",
        "Required parameter requestParameters.file was null or undefined when calling deletePrescription."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/prescription/prescription_paths/{file}`
          .replace(`{${"counseling_id"}}`, encodeURIComponent(String(requestParameters.counselingId)))
          .replace(`{${"file"}}`, encodeURIComponent(String(requestParameters.file))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete prescription images
   */
  async deletePrescription(requestParameters: DeletePrescriptionRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deletePrescriptionRaw(requestParameters, initOverrides);
  }

  /**
   * Upload multiple prescription
   */
  async postPrescriptionRaw(
    requestParameters: PostPrescriptionRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorPrescription>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling postPrescription."
      );
    }

    if (
      requestParameters.pharmacyCounselorPrescriptionRegistrable === null ||
      requestParameters.pharmacyCounselorPrescriptionRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyCounselorPrescriptionRegistrable",
        "Required parameter requestParameters.pharmacyCounselorPrescriptionRegistrable was null or undefined when calling postPrescription."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/prescription`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyCounselorPrescriptionRegistrableToJSON(
          requestParameters.pharmacyCounselorPrescriptionRegistrable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorPrescriptionFromJSON(jsonValue));
  }

  /**
   * Upload multiple prescription
   */
  async postPrescription(
    requestParameters: PostPrescriptionRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorPrescription> {
    return promiseRetry(
      (retry) =>
        this.postPrescriptionRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update multiple prescription
   */
  async putPrescriptionRaw(
    requestParameters: PutPrescriptionRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorPrescription>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling putPrescription."
      );
    }

    if (
      requestParameters.pharmacyCounselorPrescriptionRegistrable === null ||
      requestParameters.pharmacyCounselorPrescriptionRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyCounselorPrescriptionRegistrable",
        "Required parameter requestParameters.pharmacyCounselorPrescriptionRegistrable was null or undefined when calling putPrescription."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/prescription`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyCounselorPrescriptionRegistrableToJSON(
          requestParameters.pharmacyCounselorPrescriptionRegistrable
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorPrescriptionFromJSON(jsonValue));
  }

  /**
   * Update multiple prescription
   */
  async putPrescription(
    requestParameters: PutPrescriptionRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorPrescription> {
    return promiseRetry(
      (retry) =>
        this.putPrescriptionRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
