/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatientMedicineTakenLog,
  PatientMedicineTakenLogFromJSON,
  PatientMedicineTakenLogToJSON,
  PatientMedicineTakenLogs,
  PatientMedicineTakenLogsFromJSON,
  PatientMedicineTakenLogsToJSON,
  PostMedicineTakenLogRequestBody,
  PostMedicineTakenLogRequestBodyFromJSON,
  PostMedicineTakenLogRequestBodyToJSON,
  PutMedicineTakenLogRequestBody,
  PutMedicineTakenLogRequestBodyFromJSON,
  PutMedicineTakenLogRequestBodyToJSON,
} from "../models";

export interface GetMedicineTakenLogRequest {
  chainId: number;
  date: string;
  dispenseRecordId?: number;
  otcMedicineId?: number;
  familyAccountId?: number;
}

export interface PostMedicineTakenLogRequest {
  chainId: number;
  postMedicineTakenLogRequestBody: PostMedicineTakenLogRequestBody;
  dispenseRecordId?: number;
  otcMedicineId?: number;
  familyAccountId?: number;
}

export interface PutMedicineTakenLogRequest {
  medicineTakenLogId: string;
  chainId: number;
  putMedicineTakenLogRequestBody: PutMedicineTakenLogRequestBody;
}

/**
 *
 */
export class MedicineTakenLogApi extends runtime.BaseAPI {
  /**
   * Get medicine taken logs
   */
  async getMedicineTakenLogRaw(
    requestParameters: GetMedicineTakenLogRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineTakenLogs>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getMedicineTakenLog."
      );
    }

    if (requestParameters.date === null || requestParameters.date === undefined) {
      throw new runtime.RequiredError(
        "date",
        "Required parameter requestParameters.date was null or undefined when calling getMedicineTakenLog."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.dispenseRecordId !== undefined) {
      queryParameters["dispense_record_id"] = requestParameters.dispenseRecordId;
    }

    if (requestParameters.otcMedicineId !== undefined) {
      queryParameters["otc_medicine_id"] = requestParameters.otcMedicineId;
    }

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.date !== undefined) {
      queryParameters["date"] = requestParameters.date;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medicine-taken-logs`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineTakenLogsFromJSON(jsonValue));
  }

  /**
   * Get medicine taken logs
   */
  async getMedicineTakenLog(
    requestParameters: GetMedicineTakenLogRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineTakenLogs> {
    return promiseRetry(
      (retry) =>
        this.getMedicineTakenLogRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Post medicine taken log
   */
  async postMedicineTakenLogRaw(
    requestParameters: PostMedicineTakenLogRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineTakenLog>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postMedicineTakenLog."
      );
    }

    if (
      requestParameters.postMedicineTakenLogRequestBody === null ||
      requestParameters.postMedicineTakenLogRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postMedicineTakenLogRequestBody",
        "Required parameter requestParameters.postMedicineTakenLogRequestBody was null or undefined when calling postMedicineTakenLog."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.dispenseRecordId !== undefined) {
      queryParameters["dispense_record_id"] = requestParameters.dispenseRecordId;
    }

    if (requestParameters.otcMedicineId !== undefined) {
      queryParameters["otc_medicine_id"] = requestParameters.otcMedicineId;
    }

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medicine-taken-logs`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostMedicineTakenLogRequestBodyToJSON(requestParameters.postMedicineTakenLogRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineTakenLogFromJSON(jsonValue));
  }

  /**
   * Post medicine taken log
   */
  async postMedicineTakenLog(
    requestParameters: PostMedicineTakenLogRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineTakenLog> {
    return promiseRetry(
      (retry) =>
        this.postMedicineTakenLogRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Put medicine taken log
   */
  async putMedicineTakenLogRaw(
    requestParameters: PutMedicineTakenLogRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineTakenLog>> {
    if (requestParameters.medicineTakenLogId === null || requestParameters.medicineTakenLogId === undefined) {
      throw new runtime.RequiredError(
        "medicineTakenLogId",
        "Required parameter requestParameters.medicineTakenLogId was null or undefined when calling putMedicineTakenLog."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putMedicineTakenLog."
      );
    }

    if (
      requestParameters.putMedicineTakenLogRequestBody === null ||
      requestParameters.putMedicineTakenLogRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putMedicineTakenLogRequestBody",
        "Required parameter requestParameters.putMedicineTakenLogRequestBody was null or undefined when calling putMedicineTakenLog."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medicine-taken-logs/{medicine_taken_log_id}`
          .replace(`{${"medicine_taken_log_id"}}`, encodeURIComponent(String(requestParameters.medicineTakenLogId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutMedicineTakenLogRequestBodyToJSON(requestParameters.putMedicineTakenLogRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineTakenLogFromJSON(jsonValue));
  }

  /**
   * Put medicine taken log
   */
  async putMedicineTakenLog(
    requestParameters: PutMedicineTakenLogRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineTakenLog> {
    return promiseRetry(
      (retry) =>
        this.putMedicineTakenLogRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
