/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import { PatientFavoritePharmacy, PatientFavoritePharmacyFromJSON, PatientFavoritePharmacyToJSON } from "../models";

export interface AddFavoritePharmaciesRequest {
  patientFavoritePharmacy: PatientFavoritePharmacy;
}

export interface DeleteFavoritePharmaciesRequest {
  patientFavoritePharmacy: PatientFavoritePharmacy;
}

/**
 *
 */
export class FavoritePharmacyApi extends runtime.BaseAPI {
  /**
   * Add patient favorite pharmacies
   */
  async addFavoritePharmaciesRaw(
    requestParameters: AddFavoritePharmaciesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientFavoritePharmacy>> {
    if (requestParameters.patientFavoritePharmacy === null || requestParameters.patientFavoritePharmacy === undefined) {
      throw new runtime.RequiredError(
        "patientFavoritePharmacy",
        "Required parameter requestParameters.patientFavoritePharmacy was null or undefined when calling addFavoritePharmacies."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/favorite-pharmacies`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PatientFavoritePharmacyToJSON(requestParameters.patientFavoritePharmacy),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientFavoritePharmacyFromJSON(jsonValue));
  }

  /**
   * Add patient favorite pharmacies
   */
  async addFavoritePharmacies(
    requestParameters: AddFavoritePharmaciesRequest,
    initOverrides?: RequestInit
  ): Promise<PatientFavoritePharmacy> {
    return promiseRetry(
      (retry) =>
        this.addFavoritePharmaciesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Delete patient favorite pharmacies
   */
  async deleteFavoritePharmaciesRaw(
    requestParameters: DeleteFavoritePharmaciesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientFavoritePharmacy>> {
    if (requestParameters.patientFavoritePharmacy === null || requestParameters.patientFavoritePharmacy === undefined) {
      throw new runtime.RequiredError(
        "patientFavoritePharmacy",
        "Required parameter requestParameters.patientFavoritePharmacy was null or undefined when calling deleteFavoritePharmacies."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/favorite-pharmacies/delete`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PatientFavoritePharmacyToJSON(requestParameters.patientFavoritePharmacy),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientFavoritePharmacyFromJSON(jsonValue));
  }

  /**
   * Delete patient favorite pharmacies
   */
  async deleteFavoritePharmacies(
    requestParameters: DeleteFavoritePharmaciesRequest,
    initOverrides?: RequestInit
  ): Promise<PatientFavoritePharmacy> {
    return promiseRetry(
      (retry) =>
        this.deleteFavoritePharmaciesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
