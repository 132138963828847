/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  ChatterTextMessage,
  ChatterTextMessageFromJSON,
  ChatterTextMessageToJSON,
  ChatterTextMessageRegistrable,
  ChatterTextMessageRegistrableFromJSON,
  ChatterTextMessageRegistrableToJSON,
  ChatterTextMessageUpdatable,
  ChatterTextMessageUpdatableFromJSON,
  ChatterTextMessageUpdatableToJSON,
  GetTextMessagesResponse,
  GetTextMessagesResponseFromJSON,
  GetTextMessagesResponseToJSON,
} from "../models";

export interface DeleteTextMessageRequest {
  textMessageId: string;
}

export interface GetTextMessageRequest {
  textMessageId: string;
}

export interface GetTextMessagesRequest {
  from?: string;
  to?: string;
  name?: string;
  status?: GetTextMessagesStatusEnum;
  limit?: number;
  offset?: number;
  orderBy?: GetTextMessagesOrderByEnum;
}

export interface PatchTextMessageRequest {
  textMessageId: string;
  chatterTextMessageUpdatable: ChatterTextMessageUpdatable;
}

export interface PostTextMessageRequest {
  chatterTextMessageRegistrable: ChatterTextMessageRegistrable;
}

/**
 *
 */
export class TextMessageApi extends runtime.BaseAPI {
  /**
   * Cancel a text message
   */
  async deleteTextMessageRaw(
    requestParameters: DeleteTextMessageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.textMessageId === null || requestParameters.textMessageId === undefined) {
      throw new runtime.RequiredError(
        "textMessageId",
        "Required parameter requestParameters.textMessageId was null or undefined when calling deleteTextMessage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/text-messages/{text_message_id}`.replace(
          `{${"text_message_id"}}`,
          encodeURIComponent(String(requestParameters.textMessageId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Cancel a text message
   */
  async deleteTextMessage(requestParameters: DeleteTextMessageRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteTextMessageRaw(requestParameters, initOverrides);
  }

  /**
   * Get a text message
   */
  async getTextMessageRaw(
    requestParameters: GetTextMessageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ChatterTextMessage>> {
    if (requestParameters.textMessageId === null || requestParameters.textMessageId === undefined) {
      throw new runtime.RequiredError(
        "textMessageId",
        "Required parameter requestParameters.textMessageId was null or undefined when calling getTextMessage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/text-messages/{text_message_id}`.replace(
          `{${"text_message_id"}}`,
          encodeURIComponent(String(requestParameters.textMessageId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ChatterTextMessageFromJSON(jsonValue));
  }

  /**
   * Get a text message
   */
  async getTextMessage(
    requestParameters: GetTextMessageRequest,
    initOverrides?: RequestInit
  ): Promise<ChatterTextMessage> {
    return promiseRetry(
      (retry) =>
        this.getTextMessageRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Search for text messages by multiple conditions
   */
  async getTextMessagesRaw(
    requestParameters: GetTextMessagesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetTextMessagesResponse>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.name !== undefined) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["order_by"] = requestParameters.orderBy;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/text-messages`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetTextMessagesResponseFromJSON(jsonValue));
  }

  /**
   * Search for text messages by multiple conditions
   */
  async getTextMessages(
    requestParameters: GetTextMessagesRequest,
    initOverrides?: RequestInit
  ): Promise<GetTextMessagesResponse> {
    return promiseRetry(
      (retry) =>
        this.getTextMessagesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update a text message
   */
  async patchTextMessageRaw(
    requestParameters: PatchTextMessageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ChatterTextMessage>> {
    if (requestParameters.textMessageId === null || requestParameters.textMessageId === undefined) {
      throw new runtime.RequiredError(
        "textMessageId",
        "Required parameter requestParameters.textMessageId was null or undefined when calling patchTextMessage."
      );
    }

    if (
      requestParameters.chatterTextMessageUpdatable === null ||
      requestParameters.chatterTextMessageUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "chatterTextMessageUpdatable",
        "Required parameter requestParameters.chatterTextMessageUpdatable was null or undefined when calling patchTextMessage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/text-messages/{text_message_id}`.replace(
          `{${"text_message_id"}}`,
          encodeURIComponent(String(requestParameters.textMessageId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: ChatterTextMessageUpdatableToJSON(requestParameters.chatterTextMessageUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ChatterTextMessageFromJSON(jsonValue));
  }

  /**
   * Update a text message
   */
  async patchTextMessage(
    requestParameters: PatchTextMessageRequest,
    initOverrides?: RequestInit
  ): Promise<ChatterTextMessage> {
    return promiseRetry(
      (retry) =>
        this.patchTextMessageRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register a text message
   */
  async postTextMessageRaw(
    requestParameters: PostTextMessageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ChatterTextMessage>> {
    if (
      requestParameters.chatterTextMessageRegistrable === null ||
      requestParameters.chatterTextMessageRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "chatterTextMessageRegistrable",
        "Required parameter requestParameters.chatterTextMessageRegistrable was null or undefined when calling postTextMessage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/text-messages`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ChatterTextMessageRegistrableToJSON(requestParameters.chatterTextMessageRegistrable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ChatterTextMessageFromJSON(jsonValue));
  }

  /**
   * Register a text message
   */
  async postTextMessage(
    requestParameters: PostTextMessageRequest,
    initOverrides?: RequestInit
  ): Promise<ChatterTextMessage> {
    return promiseRetry(
      (retry) =>
        this.postTextMessageRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetTextMessagesStatusEnum {
  Registered = "registered",
  Sent = "sent",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum GetTextMessagesOrderByEnum {
  Time = "time",
  TimeDesc = "time_desc",
}
