import {
  BillingStatusEnum,
  DispensingStatusEnum,
  FollowUpStatusEnum,
  PharmacyCounseling,
  PharmacyCounselingStatusEnum,
} from "../../PharmacyCounseling";
import { PHARMACY_COUNSELING_STATUS, PharmacyCounselingStatus } from "./PharmacyCounselingStatus";

/**
 * 薬局の予約のステータス
 * 各種ステータスあるが、業務フロー順ではなく、優先順で処理を記述する
 * 冗長だとしても、明示的にコードを記述する
 * switch case では default を使用しない
 *
 * 優先順位
 * 1: 予約キャンセル
 * 2: 決済処理中
 * 3: その他
 */
export const getPharmacyCounselingStatus = (counseling: PharmacyCounseling): PharmacyCounselingStatus => {
  /**
   * 予約キャンセル
   */
  if (counseling.status === PharmacyCounselingStatusEnum.Canceled) {
    return PHARMACY_COUNSELING_STATUS.RESERVATION_CANCELED;
  }

  /**
   * 決済処理中
   */
  if (counseling.billing?.status === BillingStatusEnum.Started) {
    return PHARMACY_COUNSELING_STATUS.BILLING_STARTED;
  }

  if (counseling.billing?.status === BillingStatusEnum.Failed) {
    return PHARMACY_COUNSELING_STATUS.BILLING_FAILED;
  }

  /**
   * その他
   */
  switch (counseling.status) {
    case PharmacyCounselingStatusEnum.Requested:
      return PHARMACY_COUNSELING_STATUS.RESERVATION_REQUESTED;

    case PharmacyCounselingStatusEnum.Accepted:
      switch (counseling.dispensing?.status) {
        case undefined:
        case DispensingStatusEnum.Initialized:
        case DispensingStatusEnum.Completed:
          return PHARMACY_COUNSELING_STATUS.RESERVATION_ACCEPTED;
        case DispensingStatusEnum.Dispensed:
          return PHARMACY_COUNSELING_STATUS.DISPENSING;
        case DispensingStatusEnum.Notified:
          return PHARMACY_COUNSELING_STATUS.DISPENSING_WITH_NOTIFIED;
      }

    case PharmacyCounselingStatusEnum.BillingProcessing:
      switch (counseling.billing?.status) {
        case undefined:
        case BillingStatusEnum.Initialized:
          return PHARMACY_COUNSELING_STATUS.BILLING_PROCESSING;
        case BillingStatusEnum.Registered:
          return PHARMACY_COUNSELING_STATUS.BILLING_REQUESTED;
        case BillingStatusEnum.Canceled:
          return PHARMACY_COUNSELING_STATUS.BILLING_CANCELED;
        case BillingStatusEnum.Succeeded:
          return PHARMACY_COUNSELING_STATUS.BILLING_COMPLETED;
      }

    case PharmacyCounselingStatusEnum.BillingCompleted:
      switch (counseling.followUp?.status) {
        case undefined:
        case FollowUpStatusEnum.Initialized:
        case FollowUpStatusEnum.Registered:
        case FollowUpStatusEnum.Answered:
        case FollowUpStatusEnum.AnswerConfirmed:
        case FollowUpStatusEnum.Completed:
        case FollowUpStatusEnum.Canceled:
          return PHARMACY_COUNSELING_STATUS.BILLING_COMPLETED;
        case FollowUpStatusEnum.Notified:
          return PHARMACY_COUNSELING_STATUS.FOLLOW_UP_ASKED;
      }

    case PharmacyCounselingStatusEnum.Waiting:
      return PHARMACY_COUNSELING_STATUS.ONLINE_COUNSELING_READY;

    case PharmacyCounselingStatusEnum.CounselingStarted:
      return PHARMACY_COUNSELING_STATUS.ONLINE_COUNSELING_STARTED;

    case PharmacyCounselingStatusEnum.CounselingCompleted:
      return PHARMACY_COUNSELING_STATUS.ONLINE_COUNSELING_COMPLETED;
  }
};
