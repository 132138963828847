import patientApi from "@mgdx/api/lib/patientApi";
import { Patient } from "@mgdx/shared/src/models/Patient";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { patientKeys } from "../../factories";

export type ApiResponseData = Patient;
type ApiError = Error;
type QueryResponseData = boolean;

type QueryOptions = Omit<
  UseQueryOptions<ApiResponseData, ApiError, QueryResponseData>,
  "queryKey" | "queryFn" | "onError" | "select"
>;

type UseCurrentPatientIsMedicineNotebookUsingQuery = {};

export const useCurrentPatientIsMedicineNotebookUsingQuery = (
  _: UseCurrentPatientIsMedicineNotebookUsingQuery,
  options?: QueryOptions
) => {
  const { data, isLoading, isPending, isSuccess, isError, error } = useQuery<
    ApiResponseData,
    ApiError,
    QueryResponseData
  >({
    queryKey: patientKeys.patient.current.queryKey,
    queryFn: () => patientApi.getCurrentPatient(),
    select: (data) => {
      if (!data.medicineNotebooks || data.medicineNotebooks.length === 0) {
        return false;
      }
      return data.medicineNotebooks[0].agreed || false;
    },
    ...options,
  });

  return { data, isLoading, isPending, isSuccess, isError, error };
};
