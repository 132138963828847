import { createAction, createReducer } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { RootState } from "./root";

export type AuthState = {
  isInitialAuthCheckCompleted: boolean;
};

export const initialAuthState: AuthState = {
  isInitialAuthCheckCompleted: false,
};

export const setIsInitialAuthCheckCompleted = createAction<boolean>("authState/isInitialAuthCheckCompleted");

export const useIsInitialAuthCheckCompleted = () =>
  useSelector<RootState, boolean>(({ authState: { isInitialAuthCheckCompleted } }) => isInitialAuthCheckCompleted);

export const authStateReducer = createReducer<AuthState>({ ...initialAuthState }, (builder) => {
  builder.addCase(setIsInitialAuthCheckCompleted, (state, action) => {
    state.isInitialAuthCheckCompleted = action.payload;
  });
});
