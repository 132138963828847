/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyFollowUpNotificationPatternTypename = "PharmacyFollowUpNotificationPattern" as const;

/**
 *
 * @export
 * @interface PharmacyFollowUpNotificationPattern
 */
export interface PharmacyFollowUpNotificationPattern {
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  followUpType?: PharmacyFollowUpNotificationPatternFollowUpTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  messageTemplate: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  notificationDays?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  notificationTime?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  orderNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  questionnairePatternId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  sendMethod?: PharmacyFollowUpNotificationPatternSendMethodEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPattern
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyFollowUpNotificationPatternFollowUpTypeEnum {
  Chain = "chain",
  Pharmacy = "pharmacy",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyFollowUpNotificationPatternSendMethodEnum {
  Sms = "sms",
  Line = "line",
  App = "app",
}

export function PharmacyFollowUpNotificationPatternFromJSON(json: any): PharmacyFollowUpNotificationPattern {
  return PharmacyFollowUpNotificationPatternFromJSONTyped(json, false);
}

export function PharmacyFollowUpNotificationPatternFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyFollowUpNotificationPattern {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyFollowUpNotificationPatternTypename,

    followUpType: !exists(json, "follow_up_type") ? undefined : json["follow_up_type"],
    id: !exists(json, "id") ? undefined : json["id"],
    messageTemplate: json["message_template"],
    name: json["name"],
    notificationDays: !exists(json, "notification_days") ? undefined : json["notification_days"],
    notificationTime: !exists(json, "notification_time") ? undefined : json["notification_time"],
    orderNumber: !exists(json, "order_number") ? undefined : json["order_number"],
    questionnairePatternId: !exists(json, "questionnaire_pattern_id") ? undefined : json["questionnaire_pattern_id"],
    sendMethod: !exists(json, "send_method") ? undefined : json["send_method"],
  };
}

export function PharmacyFollowUpNotificationPatternToJSON(object?: PharmacyFollowUpNotificationPattern | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    follow_up_type: value.followUpType,
    id: value.id,
    message_template: value.messageTemplate,
    name: value.name,
    notification_days: value.notificationDays,
    notification_time: value.notificationTime,
    order_number: value.orderNumber,
    questionnaire_pattern_id: value.questionnairePatternId,
    send_method: value.sendMethod,
  };
}
