/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatientAllPatientsFamilyPharmacistQuestionnaires,
  PatientAllPatientsFamilyPharmacistQuestionnairesFromJSON,
  PatientAllPatientsFamilyPharmacistQuestionnairesToJSON,
  PatientFamilyPharmacistQuestionnaire,
  PatientFamilyPharmacistQuestionnaireFromJSON,
  PatientFamilyPharmacistQuestionnaireToJSON,
  PutFamilyPharmacistQuestionnaireRequestBody,
  PutFamilyPharmacistQuestionnaireRequestBodyFromJSON,
  PutFamilyPharmacistQuestionnaireRequestBodyToJSON,
} from "../models";

export interface GetAllPatientsFamilyPharmacistQuestionnairesRequest {
  from?: string;
  orderBy?: GetAllPatientsFamilyPharmacistQuestionnairesOrderByEnum;
}

export interface PutFamilyPharmacistQuestionnaireRequest {
  pharmacyId: number;
  putFamilyPharmacistQuestionnaireRequestBody: PutFamilyPharmacistQuestionnaireRequestBody;
}

/**
 *
 */
export class FamilyPharmacistQuestionnaireApi extends runtime.BaseAPI {
  /**
   * get family pharmacist questionnaires for all patients by auth_id in JWT
   */
  async getAllPatientsFamilyPharmacistQuestionnairesRaw(
    requestParameters: GetAllPatientsFamilyPharmacistQuestionnairesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientAllPatientsFamilyPharmacistQuestionnaires>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["order_by"] = requestParameters.orderBy;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-pharmacist-questionnaires`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientAllPatientsFamilyPharmacistQuestionnairesFromJSON(jsonValue)
    );
  }

  /**
   * get family pharmacist questionnaires for all patients by auth_id in JWT
   */
  async getAllPatientsFamilyPharmacistQuestionnaires(
    requestParameters: GetAllPatientsFamilyPharmacistQuestionnairesRequest,
    initOverrides?: RequestInit
  ): Promise<PatientAllPatientsFamilyPharmacistQuestionnaires> {
    return promiseRetry(
      (retry) =>
        this.getAllPatientsFamilyPharmacistQuestionnairesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * register family pharmacist questionnaire by auth_id in JWT
   */
  async putFamilyPharmacistQuestionnaireRaw(
    requestParameters: PutFamilyPharmacistQuestionnaireRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientFamilyPharmacistQuestionnaire>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling putFamilyPharmacistQuestionnaire."
      );
    }

    if (
      requestParameters.putFamilyPharmacistQuestionnaireRequestBody === null ||
      requestParameters.putFamilyPharmacistQuestionnaireRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putFamilyPharmacistQuestionnaireRequestBody",
        "Required parameter requestParameters.putFamilyPharmacistQuestionnaireRequestBody was null or undefined when calling putFamilyPharmacistQuestionnaire."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/family-pharmacist-questionnaires/{pharmacy_id}`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutFamilyPharmacistQuestionnaireRequestBodyToJSON(
          requestParameters.putFamilyPharmacistQuestionnaireRequestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientFamilyPharmacistQuestionnaireFromJSON(jsonValue)
    );
  }

  /**
   * register family pharmacist questionnaire by auth_id in JWT
   */
  async putFamilyPharmacistQuestionnaire(
    requestParameters: PutFamilyPharmacistQuestionnaireRequest,
    initOverrides?: RequestInit
  ): Promise<PatientFamilyPharmacistQuestionnaire> {
    return promiseRetry(
      (retry) =>
        this.putFamilyPharmacistQuestionnaireRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetAllPatientsFamilyPharmacistQuestionnairesOrderByEnum {
  Time = "answered_time",
  TimeDesc = "answered_time_desc",
}
