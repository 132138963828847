/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  ClinicClinicFeeds,
  ClinicClinicFeedsFromJSON,
  ClinicClinicFeedsToJSON,
  ClinicDepartmentFeeds,
  ClinicDepartmentFeedsFromJSON,
  ClinicDepartmentFeedsToJSON,
  ClinicSymptomFeeds,
  ClinicSymptomFeedsFromJSON,
  ClinicSymptomFeedsToJSON,
} from "../models";

export interface GetClinicFeedsRequest {
  updatedFrom: string;
  updatedTo: string;
  id?: Array<number>;
  departmentId?: number;
  symptomId?: number;
  availableToday?: boolean;
  acceptFirstVisit?: boolean;
  clinicName?: string;
  areaName?: string;
  prefCode?: string;
  cityCode?: string;
  nearHere?: boolean;
  currentLatitude?: number;
  currentLongitude?: number;
  sources?: Array<GetClinicFeedsSourcesEnum>;
  limit?: number;
  offset?: number;
}

/**
 *
 */
export class DataFeedApi extends runtime.BaseAPI {
  /**
   * Get Clinics
   */
  async getClinicFeedsRaw(
    requestParameters: GetClinicFeedsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicClinicFeeds>> {
    if (requestParameters.updatedFrom === null || requestParameters.updatedFrom === undefined) {
      throw new runtime.RequiredError(
        "updatedFrom",
        "Required parameter requestParameters.updatedFrom was null or undefined when calling getClinicFeeds."
      );
    }

    if (requestParameters.updatedTo === null || requestParameters.updatedTo === undefined) {
      throw new runtime.RequiredError(
        "updatedTo",
        "Required parameter requestParameters.updatedTo was null or undefined when calling getClinicFeeds."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.updatedFrom !== undefined) {
      queryParameters["updated_from"] = moment(requestParameters.updatedFrom).tz("Asia/Tokyo").format();
    }

    if (requestParameters.updatedTo !== undefined) {
      queryParameters["updated_to"] = moment(requestParameters.updatedTo).tz("Asia/Tokyo").format();
    }

    if (requestParameters.id) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.departmentId !== undefined) {
      queryParameters["department_id"] = requestParameters.departmentId;
    }

    if (requestParameters.symptomId !== undefined) {
      queryParameters["symptom_id"] = requestParameters.symptomId;
    }

    if (requestParameters.availableToday !== undefined) {
      queryParameters["available_today"] = requestParameters.availableToday;
    }

    if (requestParameters.acceptFirstVisit !== undefined) {
      queryParameters["accept_first_visit"] = requestParameters.acceptFirstVisit;
    }

    if (requestParameters.clinicName !== undefined) {
      queryParameters["clinic_name"] = requestParameters.clinicName;
    }

    if (requestParameters.areaName !== undefined) {
      queryParameters["area_name"] = requestParameters.areaName;
    }

    if (requestParameters.prefCode !== undefined) {
      queryParameters["pref_code"] = requestParameters.prefCode;
    }

    if (requestParameters.cityCode !== undefined) {
      queryParameters["city_code"] = requestParameters.cityCode;
    }

    if (requestParameters.nearHere !== undefined) {
      queryParameters["near_here"] = requestParameters.nearHere;
    }

    if (requestParameters.currentLatitude !== undefined) {
      queryParameters["current_latitude"] = requestParameters.currentLatitude;
    }

    if (requestParameters.currentLongitude !== undefined) {
      queryParameters["current_longitude"] = requestParameters.currentLongitude;
    }

    if (requestParameters.sources) {
      queryParameters["sources"] = requestParameters.sources;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/clinic-feeds`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicClinicFeedsFromJSON(jsonValue));
  }

  /**
   * Get Clinics
   */
  async getClinicFeeds(
    requestParameters: GetClinicFeedsRequest,
    initOverrides?: RequestInit
  ): Promise<ClinicClinicFeeds> {
    return promiseRetry(
      (retry) =>
        this.getClinicFeedsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get Departments
   */
  async getFeedDepartmentsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClinicDepartmentFeeds>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/department-feeds`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicDepartmentFeedsFromJSON(jsonValue));
  }

  /**
   * Get Departments
   */
  async getFeedDepartments(initOverrides?: RequestInit): Promise<ClinicDepartmentFeeds> {
    return promiseRetry(
      (retry) =>
        this.getFeedDepartmentsRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get Symptoms
   */
  async getFeedSymptomsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ClinicSymptomFeeds>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/symptom-feeds`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicSymptomFeedsFromJSON(jsonValue));
  }

  /**
   * Get Symptoms
   */
  async getFeedSymptoms(initOverrides?: RequestInit): Promise<ClinicSymptomFeeds> {
    return promiseRetry(
      (retry) =>
        this.getFeedSymptomsRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetClinicFeedsSourcesEnum {
  Yqb = "yqb",
  Curon = "curon",
}
