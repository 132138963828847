/**
 * 薬局カウンセリングステータス
 * （複数のステータスをまとめたもの)
 */
export const PHARMACY_COUNSELING_STATUS = {
  /**
   * 予約依頼中
   */
  RESERVATION_REQUESTED: "RESERVATION_REQUESTED",
  /**
   * 予約受付済み
   */
  RESERVATION_ACCEPTED: "RESERVATION_ACCEPTED",
  /**
   * 予約キャンセル
   */
  RESERVATION_CANCELED: "RESERVATION_CANCELED",

  /**
   * 調剤済み
   */
  DISPENSING: "DISPENSING",
  /**
   * 調剤済み + 患者連絡済み
   */
  DISPENSING_WITH_NOTIFIED: "DISPENSING_WITH_NOTIFIED",

  /**
   * オンライン服薬指導待ち
   */
  ONLINE_COUNSELING_READY: "ONLINE_COUNSELING_READY",
  /**
   * オンライン服薬指導中
   */
  ONLINE_COUNSELING_STARTED: "ONLINE_COUNSELING_STARTED",
  /**
   * オンライン服薬指導済み
   */
  ONLINE_COUNSELING_COMPLETED: "ONLINE_COUNSELING_COMPLETED",

  /**
   * 決済中
   */
  BILLING_PROCESSING: "BILLING_PROCESSING",
  /**
   * 決済依頼中
   */
  BILLING_REQUESTED: "BILLING_REQUESTED",
  /**
   * 決済手続き中
   */
  BILLING_STARTED: "BILLING_STARTED",
  /**
   * 決済手続き失敗
   */
  BILLING_FAILED: "BILLING_FAILED",
  /**
   * 決済完了
   */
  BILLING_COMPLETED: "BILLING_COMPLETED",
  /**
   * 決済キャンセル
   */
  BILLING_CANCELED: "BILLING_CANCELED",

  /**
   * フォローアップ 送信済み
   */
  FOLLOW_UP_ASKED: "FOLLOW_UP_ASKED",
} as const;

export type PharmacyCounselingStatus = (typeof PHARMACY_COUNSELING_STATUS)[keyof typeof PHARMACY_COUNSELING_STATUS];
