/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatchClinicsShopsRequestBody,
  PatchClinicsShopsRequestBodyFromJSON,
  PatchClinicsShopsRequestBodyToJSON,
  PatchPharmaciesShopsRequestBody,
  PatchPharmaciesShopsRequestBodyFromJSON,
  PatchPharmaciesShopsRequestBodyToJSON,
  PayerClinicShop,
  PayerClinicShopFromJSON,
  PayerClinicShopToJSON,
  PayerCommonShopUpdatable,
  PayerCommonShopUpdatableFromJSON,
  PayerCommonShopUpdatableToJSON,
  PayerDefaultShop,
  PayerDefaultShopFromJSON,
  PayerDefaultShopToJSON,
  PayerPharmacyShop,
  PayerPharmacyShopFromJSON,
  PayerPharmacyShopToJSON,
  PayerShop,
  PayerShopFromJSON,
  PayerShopToJSON,
  PayerShopAuthentication,
  PayerShopAuthenticationFromJSON,
  PayerShopAuthenticationToJSON,
  PostShopReregisterAuthenticationRequestBody,
  PostShopReregisterAuthenticationRequestBodyFromJSON,
  PostShopReregisterAuthenticationRequestBodyToJSON,
  PostShopsRequestBody,
  PostShopsRequestBodyFromJSON,
  PostShopsRequestBodyToJSON,
} from "../models";

export interface DeleteClinicShopRequest {
  clinicId: number;
}

export interface DeletePharmacyShopRequest {
  pharmacyId: number;
}

export interface GetClinicsShopRequest {
  clinicId: number;
}

export interface GetPharmacyShopRequest {
  pharmacyId: number;
}

export interface PatchClinicShopRequest {
  clinicId: number;
  patchClinicsShopsRequestBody: PatchClinicsShopsRequestBody;
}

export interface PatchPharmacyShopRequest {
  pharmacyId: number;
  patchPharmaciesShopsRequestBody: PatchPharmaciesShopsRequestBody;
}

export interface PatchShopRequest {
  shopId: number;
  payerCommonShopUpdatable: PayerCommonShopUpdatable;
}

export interface PostShopAuthenticationsRequest {
  shopId: number;
  postShopReregisterAuthenticationRequestBody: PostShopReregisterAuthenticationRequestBody;
}

export interface PostShopsRequest {
  postShopsRequestBody: PostShopsRequestBody;
}

export interface PutShopTestRequest {
  shopId: number;
}

/**
 *
 */
export class ShopApi extends runtime.BaseAPI {
  /**
   * Remove Clinic to shop\'s link
   */
  async deleteClinicShopRaw(
    requestParameters: DeleteClinicShopRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling deleteClinicShop."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/shop`.replace(
          `{${"clinic_id"}}`,
          encodeURIComponent(String(requestParameters.clinicId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Remove Clinic to shop\'s link
   */
  async deleteClinicShop(requestParameters: DeleteClinicShopRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteClinicShopRaw(requestParameters, initOverrides);
  }

  /**
   * remove Pharmacy to shop\'s link
   */
  async deletePharmacyShopRaw(
    requestParameters: DeletePharmacyShopRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling deletePharmacyShop."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/shop`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * remove Pharmacy to shop\'s link
   */
  async deletePharmacyShop(requestParameters: DeletePharmacyShopRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deletePharmacyShopRaw(requestParameters, initOverrides);
  }

  /**
   * Get Clinic\'s shop
   */
  async getClinicsShopRaw(
    requestParameters: GetClinicsShopRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerClinicShop>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling getClinicsShop."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/shop`.replace(
          `{${"clinic_id"}}`,
          encodeURIComponent(String(requestParameters.clinicId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerClinicShopFromJSON(jsonValue));
  }

  /**
   * Get Clinic\'s shop
   */
  async getClinicsShop(
    requestParameters: GetClinicsShopRequest,
    initOverrides?: RequestInit
  ): Promise<PayerClinicShop> {
    return promiseRetry(
      (retry) =>
        this.getClinicsShopRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get default shop
   */
  async getDefaultShopRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PayerDefaultShop>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/default-shop`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerDefaultShopFromJSON(jsonValue));
  }

  /**
   * Get default shop
   */
  async getDefaultShop(initOverrides?: RequestInit): Promise<PayerDefaultShop> {
    return promiseRetry(
      (retry) =>
        this.getDefaultShopRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get Pharmacy shop
   */
  async getPharmacyShopRaw(
    requestParameters: GetPharmacyShopRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerPharmacyShop>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyShop."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/shop`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerPharmacyShopFromJSON(jsonValue));
  }

  /**
   * Get Pharmacy shop
   */
  async getPharmacyShop(
    requestParameters: GetPharmacyShopRequest,
    initOverrides?: RequestInit
  ): Promise<PayerPharmacyShop> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyShopRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get shops
   */
  async getShopsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PayerShop>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/v2/shops`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PayerShopFromJSON));
  }

  /**
   * Get shops
   */
  async getShops(initOverrides?: RequestInit): Promise<Array<PayerShop>> {
    return promiseRetry(
      (retry) =>
        this.getShopsRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Append Clinic to shop
   */
  async patchClinicShopRaw(
    requestParameters: PatchClinicShopRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerClinicShop>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling patchClinicShop."
      );
    }

    if (
      requestParameters.patchClinicsShopsRequestBody === null ||
      requestParameters.patchClinicsShopsRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchClinicsShopsRequestBody",
        "Required parameter requestParameters.patchClinicsShopsRequestBody was null or undefined when calling patchClinicShop."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/shop`.replace(
          `{${"clinic_id"}}`,
          encodeURIComponent(String(requestParameters.clinicId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchClinicsShopsRequestBodyToJSON(requestParameters.patchClinicsShopsRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerClinicShopFromJSON(jsonValue));
  }

  /**
   * Append Clinic to shop
   */
  async patchClinicShop(
    requestParameters: PatchClinicShopRequest,
    initOverrides?: RequestInit
  ): Promise<PayerClinicShop> {
    return promiseRetry(
      (retry) =>
        this.patchClinicShopRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * append Pharmacy to shop
   */
  async patchPharmacyShopRaw(
    requestParameters: PatchPharmacyShopRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerPharmacyShop>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling patchPharmacyShop."
      );
    }

    if (
      requestParameters.patchPharmaciesShopsRequestBody === null ||
      requestParameters.patchPharmaciesShopsRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchPharmaciesShopsRequestBody",
        "Required parameter requestParameters.patchPharmaciesShopsRequestBody was null or undefined when calling patchPharmacyShop."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/shop`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchPharmaciesShopsRequestBodyToJSON(requestParameters.patchPharmaciesShopsRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerPharmacyShopFromJSON(jsonValue));
  }

  /**
   * append Pharmacy to shop
   */
  async patchPharmacyShop(
    requestParameters: PatchPharmacyShopRequest,
    initOverrides?: RequestInit
  ): Promise<PayerPharmacyShop> {
    return promiseRetry(
      (retry) =>
        this.patchPharmacyShopRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update shop setting
   */
  async patchShopRaw(
    requestParameters: PatchShopRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerShop>> {
    if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
      throw new runtime.RequiredError(
        "shopId",
        "Required parameter requestParameters.shopId was null or undefined when calling patchShop."
      );
    }

    if (
      requestParameters.payerCommonShopUpdatable === null ||
      requestParameters.payerCommonShopUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "payerCommonShopUpdatable",
        "Required parameter requestParameters.payerCommonShopUpdatable was null or undefined when calling patchShop."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/shops/{shop_id}`.replace(`{${"shop_id"}}`, encodeURIComponent(String(requestParameters.shopId))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PayerCommonShopUpdatableToJSON(requestParameters.payerCommonShopUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerShopFromJSON(jsonValue));
  }

  /**
   * Update shop setting
   */
  async patchShop(requestParameters: PatchShopRequest, initOverrides?: RequestInit): Promise<PayerShop> {
    return promiseRetry(
      (retry) =>
        this.patchShopRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * reregister shop authentication
   */
  async postShopAuthenticationsRaw(
    requestParameters: PostShopAuthenticationsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerShopAuthentication>> {
    if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
      throw new runtime.RequiredError(
        "shopId",
        "Required parameter requestParameters.shopId was null or undefined when calling postShopAuthentications."
      );
    }

    if (
      requestParameters.postShopReregisterAuthenticationRequestBody === null ||
      requestParameters.postShopReregisterAuthenticationRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postShopReregisterAuthenticationRequestBody",
        "Required parameter requestParameters.postShopReregisterAuthenticationRequestBody was null or undefined when calling postShopAuthentications."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/shops/{shop_id}/reregister-authentications`.replace(
          `{${"shop_id"}}`,
          encodeURIComponent(String(requestParameters.shopId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostShopReregisterAuthenticationRequestBodyToJSON(
          requestParameters.postShopReregisterAuthenticationRequestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerShopAuthenticationFromJSON(jsonValue));
  }

  /**
   * reregister shop authentication
   */
  async postShopAuthentications(
    requestParameters: PostShopAuthenticationsRequest,
    initOverrides?: RequestInit
  ): Promise<PayerShopAuthentication> {
    return promiseRetry(
      (retry) =>
        this.postShopAuthenticationsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register shop
   */
  async postShopsRaw(
    requestParameters: PostShopsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerShop>> {
    if (requestParameters.postShopsRequestBody === null || requestParameters.postShopsRequestBody === undefined) {
      throw new runtime.RequiredError(
        "postShopsRequestBody",
        "Required parameter requestParameters.postShopsRequestBody was null or undefined when calling postShops."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/shops`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostShopsRequestBodyToJSON(requestParameters.postShopsRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerShopFromJSON(jsonValue));
  }

  /**
   * Register shop
   */
  async postShops(requestParameters: PostShopsRequest, initOverrides?: RequestInit): Promise<PayerShop> {
    return promiseRetry(
      (retry) =>
        this.postShopsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * testing shop setting
   */
  async putShopTestRaw(
    requestParameters: PutShopTestRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerShop>> {
    if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
      throw new runtime.RequiredError(
        "shopId",
        "Required parameter requestParameters.shopId was null or undefined when calling putShopTest."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/shops/{shop_id}/test`.replace(`{${"shop_id"}}`, encodeURIComponent(String(requestParameters.shopId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerShopFromJSON(jsonValue));
  }

  /**
   * testing shop setting
   */
  async putShopTest(requestParameters: PutShopTestRequest, initOverrides?: RequestInit): Promise<PayerShop> {
    return promiseRetry(
      (retry) =>
        this.putShopTestRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
