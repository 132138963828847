/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PharmacyCounselorFollowUp,
  PharmacyCounselorFollowUpFromJSON,
  PharmacyCounselorFollowUpToJSON,
  PharmacyCounselorFollowUpUpdatable,
  PharmacyCounselorFollowUpUpdatableFromJSON,
  PharmacyCounselorFollowUpUpdatableToJSON,
  PutFollowUpAnswerRequestBody,
  PutFollowUpAnswerRequestBodyFromJSON,
  PutFollowUpAnswerRequestBodyToJSON,
  PutFollowUpAnswerResponse,
  PutFollowUpAnswerResponseFromJSON,
  PutFollowUpAnswerResponseToJSON,
  PutFollowUpThanksMessageRequestBody,
  PutFollowUpThanksMessageRequestBodyFromJSON,
  PutFollowUpThanksMessageRequestBodyToJSON,
} from "../models";

export interface DeleteFollowUpRequest {
  counselingId: number;
}

export interface PatchFollowUpRequest {
  counselingId: number;
  pharmacyCounselorFollowUpUpdatable: PharmacyCounselorFollowUpUpdatable;
}

export interface PutFollowUpAnswersRequest {
  counselingId: number;
  putFollowUpAnswerRequestBody: PutFollowUpAnswerRequestBody;
}

export interface PutFollowUpThanksMessageRequest {
  counselingId: number;
  putFollowUpThanksMessageRequestBody: PutFollowUpThanksMessageRequestBody;
}

/**
 *
 */
export class FollowUpApi extends runtime.BaseAPI {
  /**
   * cancel follow-up
   */
  async deleteFollowUpRaw(
    requestParameters: DeleteFollowUpRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorFollowUp>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling deleteFollowUp."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/follow-up`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorFollowUpFromJSON(jsonValue));
  }

  /**
   * cancel follow-up
   */
  async deleteFollowUp(
    requestParameters: DeleteFollowUpRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorFollowUp> {
    return promiseRetry(
      (retry) =>
        this.deleteFollowUpRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * update follow-up informations or change status to completed
   */
  async patchFollowUpRaw(
    requestParameters: PatchFollowUpRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorFollowUp>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling patchFollowUp."
      );
    }

    if (
      requestParameters.pharmacyCounselorFollowUpUpdatable === null ||
      requestParameters.pharmacyCounselorFollowUpUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyCounselorFollowUpUpdatable",
        "Required parameter requestParameters.pharmacyCounselorFollowUpUpdatable was null or undefined when calling patchFollowUp."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/follow-up`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyCounselorFollowUpUpdatableToJSON(requestParameters.pharmacyCounselorFollowUpUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorFollowUpFromJSON(jsonValue));
  }

  /**
   * update follow-up informations or change status to completed
   */
  async patchFollowUp(
    requestParameters: PatchFollowUpRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorFollowUp> {
    return promiseRetry(
      (retry) =>
        this.patchFollowUpRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * answer the questionnaires
   */
  async putFollowUpAnswersRaw(
    requestParameters: PutFollowUpAnswersRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PutFollowUpAnswerResponse>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling putFollowUpAnswers."
      );
    }

    if (
      requestParameters.putFollowUpAnswerRequestBody === null ||
      requestParameters.putFollowUpAnswerRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putFollowUpAnswerRequestBody",
        "Required parameter requestParameters.putFollowUpAnswerRequestBody was null or undefined when calling putFollowUpAnswers."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/follow-up/answers`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutFollowUpAnswerRequestBodyToJSON(requestParameters.putFollowUpAnswerRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PutFollowUpAnswerResponseFromJSON(jsonValue));
  }

  /**
   * answer the questionnaires
   */
  async putFollowUpAnswers(
    requestParameters: PutFollowUpAnswersRequest,
    initOverrides?: RequestInit
  ): Promise<PutFollowUpAnswerResponse> {
    return promiseRetry(
      (retry) =>
        this.putFollowUpAnswersRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * send thanks-message
   */
  async putFollowUpThanksMessageRaw(
    requestParameters: PutFollowUpThanksMessageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorFollowUp>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling putFollowUpThanksMessage."
      );
    }

    if (
      requestParameters.putFollowUpThanksMessageRequestBody === null ||
      requestParameters.putFollowUpThanksMessageRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putFollowUpThanksMessageRequestBody",
        "Required parameter requestParameters.putFollowUpThanksMessageRequestBody was null or undefined when calling putFollowUpThanksMessage."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/follow-up/thanks-message`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutFollowUpThanksMessageRequestBodyToJSON(requestParameters.putFollowUpThanksMessageRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorFollowUpFromJSON(jsonValue));
  }

  /**
   * send thanks-message
   */
  async putFollowUpThanksMessage(
    requestParameters: PutFollowUpThanksMessageRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorFollowUp> {
    return promiseRetry(
      (retry) =>
        this.putFollowUpThanksMessageRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              const accessToken = await forceRefreshAccessToken();
              if (accessToken) {
                retry(error);
              }
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        retries: 5,
        // The exponential factor to use. Default is 2.
        factor: 4,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
