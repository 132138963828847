import { errorHandlerReport } from "@mgdx-libs/error-handler";
import { getFirebaseFirestore, useDocument } from "@mgdx-libs/firebase";
import { doc, updateDoc } from "firebase/firestore";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useRef } from "react";

import { ROOM_COLLECTION_NAME } from "../constants";
import { roomConverter, TalkRoomDocumentWithId } from "../models";
import { TalkUnreadCountMismatchError } from "../models/TalkUnreadCountMismatchError";

export type UseTalkRoomDocumentProps = {
  roomId?: string;
};

export type UseTalkRoomDocument = (props: UseTalkRoomDocumentProps) => {
  room?: TalkRoomDocumentWithId;
  isLoading: boolean;
};

export const useTalkRoomDocument: UseTalkRoomDocument = ({ roomId = "none" }) => {
  const roomRef = doc(getFirebaseFirestore(), ROOM_COLLECTION_NAME, roomId).withConverter(roomConverter);

  const updateMedicalUnreadMessageCountRef = useRef(
    debounce((medicalUnreadMessageCount: number) => {
      updateDoc(roomRef, {
        medicalUnreadMessageCount,
      });
      try {
        throw new TalkUnreadCountMismatchError(
          `Medical unread message counts do not match. Correct the count to ${medicalUnreadMessageCount}.`
        );
      } catch (e) {
        errorHandlerReport(e);
      }
    }, 200)
  );

  const updatePatientUnreadMessageCountRef = useRef(
    debounce((patientUnreadMessageCount: number) => {
      updateDoc(roomRef, {
        patientUnreadMessageCount,
      });
      try {
        throw new TalkUnreadCountMismatchError(
          `Patient unread message counts do not match. Correct the count to ${patientUnreadMessageCount}.`
        );
      } catch (e) {
        errorHandlerReport(e);
      }
    }, 200)
  );

  const [room, isLoading] = useDocument(roomRef);

  const data = useMemo(
    () => ({
      room: room?.data(),
      isLoading,
    }),
    [isLoading, room]
  );

  useEffect(() => {
    if (!data) return;
    if (!data.room) return;
    if (data.isLoading) return;

    if (
      data.room.medicalUnreadMessageIds &&
      data.room.medicalUnreadMessageCount !== data.room.medicalUnreadMessageIds.length
    ) {
      updateMedicalUnreadMessageCountRef.current(data.room.medicalUnreadMessageIds.length);
    }

    if (
      data.room.patientUnreadMessageIds &&
      data.room.patientUnreadMessageCount !== data.room.patientUnreadMessageIds.length
    ) {
      updatePatientUnreadMessageCountRef.current(data.room.patientUnreadMessageIds.length);
    }
  }, [data]);

  return data;
};
