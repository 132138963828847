import { PharmacyPharmacyFromJSON } from "@mgdx/api/lib/pharmacy/pharmacy";
import { WrapPageElementNodeArgs } from "gatsby";
import type { HelmetProps } from "react-helmet";

import { IndexedPageType } from "./getIndexedPageType";

const parsePharmacyFromPageContext = (pageContext: WrapPageElementNodeArgs["props"]["pageContext"]) => {
  try {
    return PharmacyPharmacyFromJSON(pageContext?.pharmacy);
  } catch (e) {
    throw new Error(`indexed-content-plugin: pageContext.pharmacy is not an object (${typeof pageContext})`);
  }
};

type IndexedLink = NonNullable<HelmetProps["link"]>;

export const getIndexedLinkByPageType = ({
  indexedPageType,
  location,
  pageContext,
}: {
  indexedPageType: IndexedPageType;
  location: Pick<Location, "pathname" | "search" | "hash">;
  pageContext: WrapPageElementNodeArgs["props"]["pageContext"];
}): IndexedLink => {
  const pathname = location.pathname + location.search + location.hash;

  const isRootDomainYqb = process.env.MGDX_APP_NAME === "yqb";

  if (indexedPageType === "ROOT_PAGE") return [];

  if (indexedPageType === "NONE") return [];

  if (indexedPageType === "INDEXED_PAGE") {
    if (isRootDomainYqb) {
      return (process.env.MGDX_YQB_SUB_DOMAIN_URLS || "").split(",").map((subDomainUrl) => ({
        rel: "alternate",
        href: subDomainUrl + pathname,
      }));
    } else {
      return [
        {
          rel: "canonical",
          href: process.env.MGDX_YQB_URL + pathname,
        },
      ];
    }
  }

  if (indexedPageType === "INDEXED_PHARMACY_PAGE") {
    const pharmacy = parsePharmacyFromPageContext(pageContext);
    if (pharmacy.chainFqdn) {
      if (isRootDomainYqb) {
        return [
          {
            rel: "canonical",
            href: "https://" + pharmacy.chainFqdn + pathname,
          },
        ];
      } else {
        return [
          {
            rel: "alternate",
            href: process.env.MGDX_YQB_ROOT_DOMAIN_URL + pathname,
          },
        ];
      }
    } else {
      return [];
    }
  }

  throw new Error(`indexed-content-plugin: Unknown indexedPageType (${indexedPageType})`);
};
